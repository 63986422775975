import React from 'react';
import { StyleSheet, View, TouchableOpacity, Text } from 'react-native';

import { Camera } from "./svg/SnapioImages";

const NewSnapFooter = ({ onPress }) => (
    <View style={styles.container}>
        <TouchableOpacity style={styles.snapBtn} onPress={onPress}>
            <Camera />
            <Text style={styles.snapBtnText}>New snap</Text>
        </TouchableOpacity>
    </View>
)

export default NewSnapFooter;

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#602C8B',
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center'
    },
    snapBtn: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: 8,
        paddingHorizontal: 16,
        paddingBottom: 10,
        borderRadius: 22,
        marginVertical: 8,
        backgroundColor: 'rgba(255, 255, 255, 0.2)'
    },
    snapBtnText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 11,
        lineHeight: 28,
        color: '#f6f6f6',
        marginLeft: 12,
        textTransform: 'uppercase',
    }
})
