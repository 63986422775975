import React, { useState } from 'react'
import { View, Text, TouchableOpacity, TextInput } from 'react-native'

import ButtonPrimary from '../../components/buttonPrimary'

import { connect } from 'react-redux';
import { updateUserData } from "../../redux/user/user.actions";

import { BackButton } from '../../components/svg/SnapioImages'
import globalStyles, { colors } from '../../assets/globalStyles'

const LoginWelcome = ({ navigation, updateUserData, user }) => {
    const [email, setEmail] = useState('');
    const [userData, setUserData] = useState(user.userData);

    return (
        <View style={{ flex: 1 }}>
            <View style={globalStyles.headerContainer}>
                <TouchableOpacity style={globalStyles.headerBackButton} onPress={() => navigation.goBack()}><BackButton /></TouchableOpacity>
                <Text style={globalStyles.headerText}>Log in</Text>
            </View>
            <View style={{ flex: 2, paddingHorizontal: 20, marginTop: 24 }}>
                <Text style={[globalStyles.h2, { textAlign: 'center', marginBottom: 14 }]}>Welcome to Snapio</Text>
                <Text style={[
                    globalStyles.highlight,
                    { textAlign: 'center', fontSize: 21, lineHeight: 32 }
                ]}>
                    Looks like it’s your first time here! To complete registration we need your e-mail to send invoices
                </Text>
                <View style={{ marginTop: 61 }}>
                    <View>
                        <Text style={[globalStyles.inputLabel]}>Your e-mail</Text>
                        <TextInput style={globalStyles.inputField}
                            textContentType="emailAddress"
                            keyboardType="email-address"
                            value={userData.email || ''}
                            onChangeText={text => {
                                setUserData({
                                    ...userData,
                                    email: text
                                })
                            }}
                        />
                    </View>
                </View>
                <ButtonPrimary
                    style={{ marginTop: 'auto' }}
                    title="FINISH REGISTRATION"
                    onPress={() => {
                        // TODO: There is no api to update only email
                        updateUserData(userData)
                            .then(navigation.navigate('MyStack', { screen: 'List Product' }))
                    }}
                />
            </View>
        </View>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    updateUserData: (userData) => dispatch(updateUserData(userData))
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginWelcome)