import { SELECT_COUNTRY_CODE } from './input.types'

const initialState = {
    countryCode: {},
}

const countryCodeReducer = (state = initialState, action) => {

    switch (action.type) {
        case SELECT_COUNTRY_CODE:
            return {
                ...state,
                countryCode: action.data
            }
        default:
            return state
    }
}

export default countryCodeReducer
