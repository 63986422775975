import React from "react";
import { View, Text, StyleSheet, TouchableOpacity, Modal, Platform } from 'react-native';
import ModalWeb from 'modal-react-native-web';

import { CloseModal } from "./svg/SnapioImages";

import globalStyles from "../assets/globalStyles";
import { ScrollView } from "react-native-gesture-handler";

const FullScreenModal = ({ modalTitle, content, visible, onPress, navigation }) => (
    <View>
        {
            Platform.OS === 'web' ?
                <ModalWeb
                    visible={visible}
                    ariaHideApp={false}
                >
                    <View style={styles.headerContainer}>
                        <Text style={styles.headerText}>{modalTitle}</Text>
                        <TouchableOpacity style={styles.close} onPress={onPress}><CloseModal /></TouchableOpacity>
                    </View>
                    <ScrollView style={styles.container}>
                        <Text style={[globalStyles.h3, { marginTop: 24, marginBottom: 8 }]}>General</Text>
                        <Text style={globalStyles.p}>
                            A. The Apple software (including Boot ROM code), any third party software, documentation, interfaces, content, fonts and any data accompanying this License whether preinstalled on Apple-branded hardware, on disk, in read only memory, on any other media or in any other form (collectively the “Apple Software”) are licensed, not sold, to you by Apple Inc. (“Apple”) for use only under the terms of this License. Apple and/or Apple’s licensors retain ownership of the Apple Software itself and reserve all rights not expressly granted to you. You agree that the terms of this License will apply to any Apple branded application software product that may be preinstalled on your Apple-branded hardware, unless such product is accompanied by a separate license, in which case you agree that the terms of that license will govern your use of that product.
                        </Text>
                        <Text style={globalStyles.p}>
                            B. Apple, at its discretion, may make available future upgrades or updates to the Apple Software for your Apple-branded computer. Upgrades and updates, if any, may not necessarily include all existing software features or new features that Apple releases for newer or other models of Apple-branded computers. The terms of this License will govern any software upgrades or updates provided by Apple that replace and/or supplement the original Apple Software product, unless such upgrade or update is accompanied by a separate license in which case the terms of that license will govern.
                            </Text>
                        <Text style={globalStyles.p}>
                            C. Title and intellectual property rights in and to any content displayed by or accessed through the Apple
                           </Text>
                        <Text style={globalStyles.p}>
                            Software belongs to the respective content owner. Such content may be protected by copyright or other
                            </Text>
                    </ScrollView>
                </ModalWeb>
                :
                <Modal
                    visible={visible}
                >
                    <View style={styles.headerContainer}>
                        <Text style={styles.headerText}>{modalTitle}</Text>
                        <TouchableOpacity style={styles.close} onPress={onPress}><CloseModal /></TouchableOpacity>
                    </View>
                    <ScrollView style={styles.container}>
                        <Text style={[globalStyles.h3, { marginTop: 24, marginBottom: 8 }]}>General</Text>
                        <Text style={globalStyles.p}>
                            A. The Apple software (including Boot ROM code), any third party software, documentation, interfaces, content, fonts and any data accompanying this License whether preinstalled on Apple-branded hardware, on disk, in read only memory, on any other media or in any other form (collectively the “Apple Software”) are licensed, not sold, to you by Apple Inc. (“Apple”) for use only under the terms of this License. Apple and/or Apple’s licensors retain ownership of the Apple Software itself and reserve all rights not expressly granted to you. You agree that the terms of this License will apply to any Apple branded application software product that may be preinstalled on your Apple-branded hardware, unless such product is accompanied by a separate license, in which case you agree that the terms of that license will govern your use of that product.
                        </Text>
                        <Text style={globalStyles.p}>
                            B. Apple, at its discretion, may make available future upgrades or updates to the Apple Software for your Apple-branded computer. Upgrades and updates, if any, may not necessarily include all existing software features or new features that Apple releases for newer or other models of Apple-branded computers. The terms of this License will govern any software upgrades or updates provided by Apple that replace and/or supplement the original Apple Software product, unless such upgrade or update is accompanied by a separate license in which case the terms of that license will govern.
                            </Text>
                        <Text style={globalStyles.p}>
                            C. Title and intellectual property rights in and to any content displayed by or accessed through the Apple
                           </Text>
                        <Text style={globalStyles.p}>
                            Software belongs to the respective content owner. Such content may be protected by copyright or other
                            </Text>
                    </ScrollView>
                </Modal>
        }
    </View>
)

export default FullScreenModal

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        width: '100%',
        backgroundColor: 'white',
    },
    headerContainer: {
        paddingTop: 16,
        paddingBottom: 17,
        borderBottomWidth: 1,
        borderColor: 'rgba(22,22,22,0.2)',
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: 20,
        textAlign: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 16,
        lineHeight: 22,
        color: '#161616',
    },
    close: {
        position: 'absolute',
        right: 20,
        alignSelf: 'center'
    }
})