import React, { useState } from 'react'
import { View, Text, TouchableOpacity, TextInput } from 'react-native'
import '@expo/match-media'
import { useMediaQuery } from "react-responsive";
import i18n from 'i18n-js';

import ButtonPrimary from '../../components/buttonPrimary'
import CustomDropdown from '../../components/customDropdown'

import { BackButton } from '../../components/svg/SnapioImages'
import SnapioHeader from '../../components/header'
import globalStyles, { colors } from '../../assets/globalStyles'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchSms } from '../../redux/login/login.action';

// Desktop
import DesktopFooter from '../../components/desktop/desktopFooter';

const LoginPage = ({ navigation, fetchSms }) => {
    const isDesktop = useMediaQuery({
        minDeviceWidth: 1140
    })

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            fetchSms(number, navigation);
        }
    }

    const [number, setNumber] = useState('');

    if (isDesktop) {
        return (
            <View style={{ flex: 1, alignItems: 'center' }}>
                <SnapioHeader />
                <View style={{ maxWidth: 1120, flex: 2, paddingHorizontal: 20 }}>
                    <Text style={[
                        globalStyles.highlight,
                        { textAlign: 'center', fontSize: 21, lineHeight: 32, marginTop: 26 }
                    ]}>
                        {i18n.t('login.get_started')}
                    </Text>
                    <View style={{ marginTop: 40 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', zIndex: 50 }}>
                            <CustomDropdown />
                            <View style={{ flex: 2, marginLeft: 8 }}>
                                <Text style={[globalStyles.inputLabel]}>{i18n.t('login.phone_number')}</Text>
                                <TextInput style={globalStyles.inputField}
                                    onChangeText={text => setNumber(text)}
                                    onKeyPress={handleKeyDown}
                                    value={number}
                                />
                            </View>
                        </View>
                        <View>
                            <Text style={[globalStyles.tightP, { textAlign: 'center' }]}>{i18n.t('login.privacy_1')}<Text style={{ textDecorationLine: "underline" }}>{i18n.t('login.privacy_2')}</Text>
                                <Text>{i18n.t('login.privacy_3')}</Text> <Text style={{ textDecorationLine: "underline" }}>{i18n.t('login.privacy_4')}</Text></Text>
                        </View>
                    </View>
                    <ButtonPrimary
                        style={{ marginTop: 'auto' }}
                        title={i18n.t('login.receive_code')}
                        onPress={() => {
                            fetchSms(number, navigation);
                        }}
                    />
                    <DesktopFooter />
                </View>

            </View>
        )
    } else {
        return (
            <View style={{ flex: 1 }}>
                <View style={globalStyles.headerContainer}>
                    {localStorage.getItem('user-token') &&
                        <TouchableOpacity
                            style={globalStyles.headerBackButton}
                            onPress={() => {
                                navigation.canGoBack() && navigation.goBack()

                                // navigation.navigate('MyStack', { screen: 'Product' })
                            }}
                        ><BackButton /></TouchableOpacity>
                    }
                    <Text style={globalStyles.headerText}>{i18n.t('login.log_in')}</Text>
                </View>
                <View style={{ flex: 2, paddingHorizontal: 20 }}>
                    <Text style={[
                        globalStyles.highlight,
                        { textAlign: 'center', fontSize: 21, lineHeight: 32, marginTop: 26 }
                    ]}>
                        {i18n.t('login.get_started')}
                    </Text>
                    <View style={{ marginTop: 40 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', zIndex: 50 }}>
                            <CustomDropdown />
                            <View style={{ flex: 2, marginLeft: 8 }}>
                                <Text style={[globalStyles.inputLabel]}>{i18n.t('login.phone_number')}</Text>
                                <TextInput style={globalStyles.inputField}
                                    onChangeText={text => setNumber(text)}
                                    onKeyPress={handleKeyDown}
                                    value={number}
                                />
                            </View>
                        </View>
                        <View>
                            <Text style={[globalStyles.tightP, { textAlign: 'center' }]}>{i18n.t('login.privacy_1')}<Text style={{ textDecorationLine: "underline" }}>{i18n.t('login.privacy_2')}</Text>
                                <Text>{i18n.t('login.privacy_3')}</Text> <Text style={{ textDecorationLine: "underline" }}>{i18n.t('login.privacy_4')}</Text></Text>
                        </View>
                    </View>
                    <ButtonPrimary
                        style={{ marginTop: 'auto' }}
                        title={i18n.t('login.receive_code')}
                        onPress={() => {
                            fetchSms(number, navigation);
                        }}
                    />
                </View>
            </View>
        )
    }
}

const mapStateToProps = (state) => ({
    login: state.login.payload
})

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({ fetchSms }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)