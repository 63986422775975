import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet, View } from 'react-native';

const Counter = ({ title, marginTop, marginBottom, count, setCount }) => {

    const increaseCount = () => {
        setCount(count + 1);
    }

    const decreaseCount = () => {
        if (count !== 1) {
            setCount(count - 1);
        }
    }

    return (
        <View style={{
            width: '100%',
            marginTop: (marginTop || marginTop === 0) ? marginTop : 18,
            marginBottom: (marginBottom || marginBottom === 0) ? marginBottom : 19,
        }}>
            <Text style={styles.counterTitle}>{title}</Text>
            <View style={styles.counterWrap}>
                <TouchableOpacity
                    onPress={() => decreaseCount()}
                >
                    <View style={styles.countButton}>
                        <View style={styles.minus}></View>
                    </View>
                </TouchableOpacity>
                <Text style={styles.countNumber}>{count}</Text>
                <TouchableOpacity
                    onPress={() => increaseCount()}
                >
                    <View style={styles.countButton}>
                        <View style={styles.minus}></View>
                        <View style={styles.plus}></View>
                    </View>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export default Counter;

const styles = StyleSheet.create({
    counterWrap: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        alignItems: "center",
        width: '100%',
    },
    countButton: {
        width: 56,
        height: 56,
        backgroundColor: '#ebebeb',
        borderRadius: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
    },
    minus: {
        width: 20,
        height: 2,
        backgroundColor: '#602C8B',
        position: 'absolute'
    },
    plus: {
        height: 20,
        width: 2,
        backgroundColor: '#602C8B',
        position: 'absolute',
    },
    countNumber: {
        color: '#161616',
        fontFamily: 'Pragmatica-Book',
        fontSize: 45,
        lineHeight: 64,
        textAlign: 'center',
    },
    counterTitle: {
        textTransform: "uppercase",
        color: '#6C6C6C',
        textAlign: 'center',
        fontSize: 12,
        lineHeight: 20,
        fontFamily: 'Pragmatica-Book'
    }
})