import {
    SET_CURRENT_USER, EDIT_CURRENT_USER, GET_CURRENT_USER, GET_CURRENT_USER_FAIL, GET_CURRENT_USER_SUCCESS,
    UPDATE_CURRENT_USER_SUCCESS, UPDATE_CURRENT_USER, UPDATE_CURRENT_USER_FAIL
} from './user.types';

import axios from 'axios'

export const setCurrentUser = user => ({
    type: SET_CURRENT_USER,
    payload: user
})

export const editCurrentUser = (user) => ({
    type: EDIT_CURRENT_USER,
    payload: user,
})

export const getUserData = () => {
    return (dispatch) => {
        dispatch(getUser())
        return (
            axios.get('/user/current')
                .then(res => {
                    if (res.status === 200) {
                        return dispatch(getUserSuccess(res.data))
                    }
                })
                .catch(err => dispatch(getUserFail()))
        )
    }
}

export const updateUserData = (userData) => {
    return (dispatch) => {
        dispatch(updateUser());
        return (
            axios.patch('/user/current', {
                ...userData,
                "busy": true,
                "successful": false
            })
                .then(res => {
                    if (res.status === 204) {
                        updateUserSuccess();
                        getUserData();
                    }
                })
                .catch(err => {
                    dispatch(updateUserFail(err.response.data))
                })
        )
    }
}

function getUser() {
    return {
        type: GET_CURRENT_USER
    }
}

function getUserSuccess(data) {
    return {
        type: GET_CURRENT_USER_SUCCESS,
        data
    }
}

function getUserFail() {
    return {
        type: GET_CURRENT_USER_FAIL
    }
}

const updateUser = () => {
    return {
        type: UPDATE_CURRENT_USER
    }
}

const updateUserSuccess = () => {
    return {
        type: UPDATE_CURRENT_USER_SUCCESS
    }
}

const updateUserFail = (error) => {
    return {
        type: UPDATE_CURRENT_USER_FAIL,
        error
    }
}
