import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { connect } from 'react-redux';
import { selectAddon } from '../redux/product/product.actions'

const AddonList = ({ singleProduct, productOptions, selectAddon }) => {
    return (
        <View style={{ width: '100%' }}>
            {productOptions && productOptions.map((optionSet, index) =>
                <View key={optionSet.id} style={{ zIndex: 4000 - index }}>
                    <Text style={styles.optionHeader}>{optionSet.title}</Text>
                    {
                        optionSet.options && optionSet.options.length < 3 ?
                            optionSet.options.map((option, index) =>
                                <View key={option.id} style={index === 0 ? styles.optionContainerTopBotBorder : styles.optionContainer}>
                                    <TouchableOpacity
                                        style={styles.touchContainer}
                                        onPress={() => {
                                            selectAddon(optionSet.id, option.id)
                                        }}
                                    >
                                        <View style={option.selected ? styles.circleSelected : styles.circle}>
                                            {option.selected ? <View style={styles.innerCircle}></View> : null}
                                        </View>
                                        <View>
                                            <Text style={styles.optionTitle}>{option.title}</Text>
                                            <Text style={styles.optionPrice}>{option.price} {singleProduct.currency_symbol}</Text>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            )
                            :
                            <View>
                                {optionSet.options &&
                                    <DropDownPicker
                                        items={optionSet.options}
                                        containerStyle={{ height: 40 }}
                                        style={{
                                            backgroundColor: '#EBEBEB',
                                            borderRadius: 8,
                                        }}
                                        containerStyle={{
                                            height: 48
                                        }}
                                        itemStyle={{
                                            justifyContent: 'flex-start'
                                        }}
                                        dropDownStyle={{ backgroundColor: '#EBEBEB' }}
                                        onChangeItem={(option) => {
                                            selectAddon(optionSet.id, option.id)
                                        }}
                                    />
                                }

                            </View>
                    }
                </View>
            )}
        </View>
    )
}

const mapStateToProps = (state) => ({
    singleProduct: state.product.singleProduct,
    productOptions: state.product.singleProduct.addons,
})

const mapDispatchToProps = (dispatch) => ({
    selectAddon: (addonListId, addonOptionId) => dispatch(selectAddon(addonListId, addonOptionId))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddonList);

const styles = StyleSheet.create({
    optionContainer: {
        width: '100%',
        paddingTop: 21,
        paddingBottom: 18,
        borderBottomWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
    },
    optionContainerTopBotBorder: {
        width: '100%',
        paddingTop: 21,
        paddingBottom: 18,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
    },
    touchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    optionHeader: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book',
        alignSelf: 'flex-start',
        marginBottom: 17,
        marginTop: 21,
    },
    circle: {
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        borderRadius: 100,
        borderWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
    },
    circleSelected: {
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        borderRadius: 100,
        borderWidth: 1,
        backgroundColor: '#602C8B',
        borderColor: '#602C8B',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
    },
    innerCircle: {
        width: 16,
        height: 16,
        minWidth: 16,
        minHeight: 16,
        backgroundColor: '#F6F6F6',
        borderRadius: 100,
    },
    optionTitle: {
        color: '#161616',
        fontSize: 16,
        lineHeight: 24,
        fontFamily: 'Pragmatica-Book'
    },
    optionPrice: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book'
    }
})