import React from 'react'
import { View, Text, } from 'react-native';

import SnapioHeader from '../components/header';
import NewSnapFooter from '../components/newSnapFooter'

import { BagComplete } from '../components/svg/SnapioImages'
import globalStyles, { colors } from '../assets/globalStyles';

const OrderComplete = ({ navigation }) => (
    <View style={{ flex: 1, justifyContent: 'flex-start', width: '100%', height: '100%' }}>
        <SnapioHeader navigation={navigation} />
        <View style={{
            justifyContent: 'center', alignItems: 'center',
            paddingHorizontal: 20,
            paddingVertical: 16,
            width: '100%',
            flex: 2,
        }}>
            <BagComplete style={{ marginBottom: 37 }} />
            <Text style={[globalStyles.h2, { color: colors.brand1, lineHeight: 47, marginBottom: 11 }]}>Order complete!</Text>
            <Text style={[globalStyles.highlight, { textAlign: 'center', marginBottom: 16 }]}>A confirmation has been sent to your e-mail</Text>
            <Text style={[globalStyles.link]}>View order</Text>
        </View>
        <NewSnapFooter />
    </View>
)

export default OrderComplete;