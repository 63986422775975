import React, { useState, useEffect } from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    TextInput
} from 'react-native';
import i18n from 'i18n-js';

import CustomDropdown from '../components/customDropdown'
import ErrorField from '../components/errorField'

import { connect } from 'react-redux';
import { updateUserData } from "../redux/user/user.actions";

import ButtonPrimary from "../components/buttonPrimary";
import { BackButton, Error } from "../components/svg/SnapioImages";

import globalStyles from "../assets/globalStyles";

const ProfileInformation = ({ user, navigation, updateUserData }) => {
    const [userData, setUserData] = useState(user.userData);
    const error = user.errorFields.errors;

    useEffect(() => {
        console.log('get on screen');
    })

    return (
        <View style={{ minHeight: '100%' }}>
            <View style={globalStyles.headerContainer}>
                <TouchableOpacity style={globalStyles.headerBackButton} onPress={() => navigation.goBack()}><BackButton /></TouchableOpacity>
                <Text style={globalStyles.headerText}>Profile Information</Text>
            </View>
            <ScrollView style={styles.container}>
                <View style={{ marginTop: 17 }}>
                    <View style={{ flexDirection: 'row', alignItems: 'flex-end', justifyContent: 'space-between', zIndex: 50 }}>
                        <CustomDropdown />
                        <View style={{ flex: 2, marginLeft: 8 }}>
                            <Text style={[globalStyles.inputLabel]}>Phone number</Text>
                            <TextInput style={[globalStyles.inputField, user.error && error.phone_number && globalStyles.inputFieldError]}
                                onChangeText={text => {
                                    setUserData({
                                        ...userData,
                                        phone_number: text
                                    })
                                }}
                                value={userData.phone_number}
                            />
                        </View>
                    </View>
                    {user.error && error.phone_number &&
                        <ErrorField message={error.phone_number} />
                    }
                    <Text style={globalStyles.inputLabel}>Name</Text>
                    <TextInput
                        style={[globalStyles.inputField, user.error && error.first_name && globalStyles.inputFieldError]}
                        value={userData.first_name}
                        onChangeText={text => {
                            setUserData({
                                ...userData,
                                first_name: text
                            })
                        }}
                    />
                    {user.error && error.first_name && <ErrorField message={error.first_name} />}
                    <Text style={globalStyles.inputLabel}>Surname</Text>
                    <TextInput
                        style={[globalStyles.inputField, user.error && error.last_name && globalStyles.inputFieldError]}
                        value={userData.last_name}
                        onChangeText={text => {
                            setUserData({
                                ...userData,
                                last_name: text
                            })
                        }}
                    />
                    {
                        user.error &&
                        error.last_name &&
                        <ErrorField message={error.last_name} />
                    }
                    {/* QUESTION: In design here was address */}
                    {/* <Text style={globalStyles.inputLabel}>Address</Text>
                    <TextInput
                        style={globalStyles.inputField}
                        value={userData.address}
                        onChangeText={text => {
                            setUserData({
                                ...userData,
                                address: text
                            })
                        }}
                    /> */}
                    <Text style={globalStyles.inputLabel}>E-mail</Text>
                    <TextInput
                        style={[globalStyles.inputField, { marginBottom: 8 }, user.error && error.email && globalStyles.inputFieldError]}
                        value={userData.email}
                        onChangeText={text => {
                            setUserData({
                                ...userData,
                                email: text
                            })
                        }}
                    />
                    {user.error && error.email && <ErrorField message={error.email} />}
                    <Text style={globalStyles.tag}>We will send invoice to this e-mail</Text>
                    <ButtonPrimary title="SAVE CHANGES" onPress={() => {
                        updateUserData(userData)
                    }} />
                </View>
            </ScrollView>
        </View>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
    updateUserData: (userData) => dispatch(updateUserData(userData))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileInformation)


const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        width: '100%',
    },
    headerContainer: {
        paddingTop: 16,
        paddingBottom: 17,
        borderBottomWidth: 1,
        borderColor: 'rgba(22,22,22,0.2)',
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: 20,
        textAlign: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 16,
        lineHeight: 22,
        color: '#161616',
    },
    close: {
        position: 'absolute',
        right: 20,
        alignSelf: 'center'
    }
})