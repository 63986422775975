import {
    CALCULATE_DELIVERY_PRICE, CALCULATE_DELIVERY_PRICE_FAIL, CALCULATE_DELIVERY_PRICE_SUCCESS,
    GET_DELIVERY_METHODS, GET_DELIVERY_METHODS_FAIL, GET_DELIVERY_METHODS_SUCCESS,
    SELECT_DELIVERY_METHOD, SELECT_DELIVERY_METHOD_FAIL, SELECT_DELIVERY_METHOD_SUCCESS,
    GET_ORDER, GET_ORDER_FAIL, GET_ORDER_SUCCESS,
    GET_PAYMENT_METHODS, GET_PAYMENT_METHODS_FAIL, GET_PAYMENT_METHODS_SUCCESS,
    SELECT_PAYMENT_METHOD, SELECT_PAYMENT_METHOD_FAIL, SELECT_PAYMENT_METHOD_SUCCESS,
    GET_PICKUP_POINTS, GET_PICKUP_POINTS_FAIL, GET_PICKUP_POINTS_SUCCESS,
    SELECT_PICKUP_POINT, SELECT_PICKUP_POINT_FAIL, SELECT_PICKUP_POINT_SUCCESS,
    SELECT_DELIVERY_ADDRESS, SELECT_DELIVERY_ADDRESS_FAIL, SELECT_DELIVERY_ADDRESS_SUCCESS,
} from './order.types';
import store from '../store'
import axios from 'axios'

export const getOrder = () => {
    const orderToken = localStorage.getItem('orderToken');

    return (dispatch) => {
        if (orderToken) {
            dispatch(getOrderStart())
            return (
                axios.get(`/order/${orderToken}`)
                    .then(({ data }) => {
                        dispatch(getOrderSuccess(data))
                        dispatch(calculateDeliveryPrice())
                        if (store.getState().order.deliveryMethods.length === 0) {
                            dispatch(getDeliveryMethods())
                        }
                    })
                    .catch(err => { dispatch(getOrderFail()); console.log(err) })
            )
        } else {
            return (dispatch(getOrderFail()))
        }
    }
}

export const getDeliveryMethods = () => {
    const orderToken = localStorage.getItem('orderToken');

    return (dispatch) => {
        dispatch(getDeliveryMethodsStart())
        return (
            axios.get(`/delivery/methods/${orderToken}`)
                .then(({ data }) => {
                    dispatch(getDeliveryMethodsSuccess(data))
                    dispatch(getPickupPoints())
                })
                .catch(err => { dispatch(getDeliveryMethodsFail()) })
        )
    }
}

export const getPickupPoints = (id) => {
    const deliveryMethodsId = id || store.getState().order.order.delivery_method.id || store.getState().order.deliveryMethods[0].id
    return (dispatch) => {
        dispatch(getPickupPointsStart())
        return (
            axios.get(`/delivery/${deliveryMethodsId}/pickup-points`)
                .then(({ data }) => {
                    dispatch(getPickupPointsSuccess(data))
                    dispatch(getPaymentMethods())
                })
                .catch(err => dispatch(getPickupPointsFail()))
        )
    }
}

export const calculateDeliveryPrice = () => {
    const orderToken = localStorage.getItem('orderToken');

    return (dispatch) => {
        dispatch(calculateDeliveryPriceStart())
        return (
            axios.patch(`/order/${orderToken}/calculate-delivery-price`)
                .then(({ data: { payload } }) => {
                    dispatch(calculateDeliveryPriceSuccess(payload))
                })
                .catch(err => dispatch(calculateDeliveryPriceFail()))
        )
    }
}

export const getPaymentMethods = () => {
    const orderToken = localStorage.getItem('orderToken');

    return (dispatch) => {
        dispatch(getPaymentMethodsStart())
        return (
            axios.get(`/payment/${orderToken}/methods`)
                .then(({ data }) => {
                    dispatch(getPaymentMethodsSuccess(data))
                })
                .catch(err => dispatch(getPaymentMethodsFail()))
        )
    }
}

export const selectPaymentMethod = (id) => {
    const orderToken = localStorage.getItem('orderToken');

    return (dispatch) => {
        dispatch(selectPaymentMethodStart())
        return (
            axios.patch(`/order/${orderToken}/payment-method`, {
                id: id
            })
                .then(({ data: { payload } }) => {
                    dispatch(selectPaymentMethodSuccess(payload))
                })
                .catch(err => dispatch(selectPaymentMethodFail()))
        )
    }
}

export const selectDeliveryMethod = (id) => {
    const orderToken = localStorage.getItem('orderToken');

    return (dispatch) => {
        dispatch(selectDeliveryMethodStart())
        return (
            axios.patch(`/order/${orderToken}/delivery-method`, {
                delivery_method_id: id
            })
                .then(res => {
                    dispatch(selectDeliveryMethodSuccess())
                    dispatch(getOrder())
                    dispatch(getPickupPoints(id))
                })
                .catch(err => dispatch(selectDeliveryMethodFail()))
        )
    }
}

export const selectPickupPoint = (id) => {
    const orderToken = localStorage.getItem('orderToken');

    return (dispatch) => {
        dispatch(selectPickupPointStart())
        return (
            axios.patch(`/order/${orderToken}/delivery-pickup-point`, {
                delivery_pickup_point_id: id
            })
                .then(res => {
                    dispatch(selectPickupPointSuccess())
                    dispatch(getOrder())
                })
                .catch(err => dispatch(selectPickupPointFail()))
        )
    }
}

// TODO: DELIVERY ADDRESS SELECT

export const selectDeliveryAddress = (address) => {
    const orderToken = localStorage.getItem('orderToken');
    return (dispatch) => {
        dispatch(selectDeliveryAddressStart())
        return (
            axios.patch(`/order/${orderToken}/delivery-address`, {
                delivery_address_id: address.id,
                distance: 0
            })
                .then(res => {
                    dispatch(selectDeliveryAddressStart())
                    dispatch(getOrder())
                })
                .catch(err => dispatch(selectDeliveryAddressFail()))

        )
    }
}

const getOrderStart = () => {
    return {
        type: GET_ORDER
    }
}

const getOrderSuccess = (data) => {
    return {
        type: GET_ORDER_SUCCESS,
        data
    }
}

const getOrderFail = () => {
    return {
        type: GET_ORDER_FAIL
    }
}

const getDeliveryMethodsStart = () => {
    return {
        type: GET_DELIVERY_METHODS
    }
}

const getDeliveryMethodsSuccess = (data) => {
    return {
        type: GET_DELIVERY_METHODS_SUCCESS,
        data
    }
}

const getDeliveryMethodsFail = () => {
    return {
        type: GET_DELIVERY_METHODS_FAIL
    }
}

const getPickupPointsStart = () => {
    return {
        type: GET_PICKUP_POINTS
    }
}

const getPickupPointsSuccess = (data) => {
    return {
        type: GET_PICKUP_POINTS_SUCCESS,
        data
    }
}

const getPickupPointsFail = () => {
    return {
        type: GET_PICKUP_POINTS_FAIL
    }
}

const calculateDeliveryPriceStart = () => {
    return {
        type: CALCULATE_DELIVERY_PRICE
    }
}

const calculateDeliveryPriceSuccess = (data) => {
    return {
        type: CALCULATE_DELIVERY_PRICE_SUCCESS,
        data
    }
}

const calculateDeliveryPriceFail = () => {
    return {
        type: CALCULATE_DELIVERY_PRICE_FAIL
    }
}

const getPaymentMethodsStart = () => {
    return {
        type: GET_PAYMENT_METHODS
    }
}

const getPaymentMethodsSuccess = (data) => {
    return {
        type: GET_PAYMENT_METHODS_SUCCESS,
        data
    }
}

const getPaymentMethodsFail = () => {
    return {
        type: GET_PAYMENT_METHODS_FAIL
    }
}

const selectPaymentMethodStart = () => {
    return {
        type: SELECT_PAYMENT_METHOD
    }
}

const selectPaymentMethodSuccess = (data) => {
    return {
        type: SELECT_PAYMENT_METHOD_SUCCESS,
        data
    }
}

const selectPaymentMethodFail = () => {
    return {
        type: SELECT_PAYMENT_METHOD_FAIL
    }
}

const selectDeliveryMethodStart = () => {
    return {
        type: SELECT_DELIVERY_METHOD
    }
}

const selectDeliveryMethodSuccess = () => {
    return {
        type: SELECT_DELIVERY_METHOD_SUCCESS
    }
}

const selectDeliveryMethodFail = () => {
    return {
        type: SELECT_DELIVERY_METHOD_FAIL
    }
}

const selectPickupPointStart = () => {
    return {
        type: SELECT_PICKUP_POINT
    }
}

const selectPickupPointSuccess = () => {
    return {
        type: SELECT_PICKUP_POINT_SUCCESS
    }
}

const selectPickupPointFail = () => {
    return {
        type: SELECT_PICKUP_POINT_FAIL
    }
}

const selectDeliveryAddressStart = () => {
    return {
        type: SELECT_DELIVERY_ADDRESS
    }
}

const selectDeliveryAddressSuccess = () => {
    return {
        type: SELECT_DELIVERY_ADDRESS_SUCCESS
    }
}

const selectDeliveryAddressFail = () => {
    return {
        type: SELECT_DELIVERY_ADDRESS_FAIL
    }
}