import React, { useState, useEffect } from 'react';
import { StyleSheet, Button, View, Image, Text, ScrollView, TouchableOpacity, Platform } from 'react-native';
// Testing redux
import { connect } from 'react-redux';
import { addItem } from "../redux/cart/cart.actions";
import { getUserData } from '../redux/user/user.actions';
// Global styles
import globalStyles from "../assets/globalStyles";
// Our components
import SnapioHeader from "../components/header";
import NewSnapFooter from "../components/newSnapFooter";
import SnapioFooter from "../components/footer";
import OptionList from "../components/optionList";
import ButtonPrimary from "../components/buttonPrimary";
import Counter from "../components/counter";
import ProductSlider from "../components/productSlider";
import Accordion from "../components/accordion";
import AddonList from '../components/addonList';
// SVG
import { SendBox, BackButton } from "../components/svg/SnapioImages";

import axios from 'axios'

const ProductPage = ({ navigation, addItem, getUserData, singleProduct }) => {

    const data = [
        {
            title: 'Some title',
            data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        },
        {
            title: 'Some title 2',
            data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        },
        {
            title: 'Some title 3',
            data: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
        }
    ]

    const [accordionData, setAccordionData] = useState(data.map(item => { item.show = false; return item }))

    const [shopOptions, setData] = useState([
        {
            id: 1,
            selected: true,
            title: '30ml',
            price: '€38,47',
        },
        {
            id: 2,
            selected: false,
            title: '50ml',
            price: '€64,50',
        },
        // {
        //     id: 3,
        //     selected: false,
        //     title: '30ml',
        //     price: '€38,47',
        // },
        // {
        //     id: 4,
        //     selected: false,
        //     title: '50ml',
        //     price: '€64,50',
        // },
    ])

    const img = [
        {
            img_uri: "https://telegraf.bb.lv/media/k2/items/cache/90eec687f2034ef6d9174604687885b8_L.jpg"
        },
        {
            img_uri: "https://images.la.lv/uploads/2018/02/Madara-kosmetika.jpg"
        },
        {
            img_uri: "https://www.look-beautiful.de/media/image/72/e2/cf/MADARA-Cosmetics-1-min.jpg"
        }
    ]

    const [count, setCount] = useState(1)

    const [modalVisible, setModalVisible] = useState(false);

    return (
        <View style={{ flex: 1, justifyContent: 'flex-start', width: '100%', height: '100%' }}>
            <SnapioHeader navigation={navigation} />
            <ScrollView style={{ width: '100%' }} contentContainerStyle={styles.container}>
                <TouchableOpacity onPress={() => { navigation.goBack() }}>
                    <BackButton />
                </TouchableOpacity>
                {/* Company logo */}
                <Image style={styles.companyLogo} source={require('../assets/images/product/logo.png')} />
                <Text style={styles.companyTitle}>MADARA COSMETICS</Text>
                {singleProduct.name && <Text style={styles.productTitle}>{singleProduct.name}</Text>}
                {/* Product carousel */}
                {singleProduct.images && <ProductSlider swiperImages={
                    singleProduct.images.map((item) => {
                        return ({ img_uri: item })
                    })
                } />}
                {singleProduct.price && <Text style={styles.mainPrice}>{singleProduct.price}{singleProduct.currency_symbol}</Text>}
                <View style={{
                    width: '100%', ...(Platform.OS !== 'android' && {
                        zIndex: 50
                    })
                }}>
                    <View style={{ zIndex: 50 }}>
                        <AddonList />
                    </View>
                    {/* <OptionList
                        productItems={shopOptions}
                        setData={setData}
                        title='Choose volume'
                    /> */}
                    <Counter title="quantity" count={count} setCount={setCount} marginBottom={0}></Counter>
                    <ButtonPrimary
                        title={'ADD TO CART'}
                        onPress={() => {
                            addItem(singleProduct.id, count);
                        }}
                    />
                </View>


                {/* ========================= */}
                {/* === HARDCODED EXAMPLE === */}
                {/* ========================= */}
                {/* <Image style={styles.companyLogo} source={require('../assets/images/product/logo.png')} />
                <Text style={styles.companyTitle}>MADARA COSMETICS</Text>
                <Text style={styles.productTitle}>Radiant Energy serums sejai, 30ml</Text>
                
                <ProductSlider swiperImages={img} />
                <Text style={styles.mainPrice}>38,47€</Text>
                <View style={{
                    width: '100%', ...(Platform.OS !== 'android' && {
                        zIndex: 50
                    })
                }}>
                    <OptionList
                        productItems={shopOptions}
                        setData={setData}
                        title='Choose volume'
                    />
                </View>
                <Counter title="quantity" count={count} setCount={setCount} marginBottom={0}></Counter>
                <ButtonPrimary
                    title={'ADD TO CART'}
                    // onPress={() => {
                    // setModalVisible(!modalVisible);
                    // addItem(shopOptions) 
                    // }} 
                    onPress={() => {
                        shopOptions.map(item => item.selected && addItem(item, count, img[0].img_uri))
                    }}
                />
                <ButtonPrimary title="TESTING" onPress={() => {
                    getUserData();
                }} />
               
                <View style={styles.infoContainer}>
                    <Image style={styles.infoLogo} source={require('../assets/images/product/logo.png')} />
                    <Text style={styles.infoTitle}>Sold </Text>
                    <Text style={[styles.infoTitle, styles.infoUnderline]}>
                        by Company
                        <View style={styles.infoUnderlineLine}>
                        </View>
                    </Text>
                </View>
                <View style={styles.infoContainer}>
                    <SendBox style={styles.sendBox} />
                    <Text style={styles.infoTitle}>Sold by Company</Text>
                </View>
                
                <View style={styles.descriptionContainer}>
                    <Text style={[globalStyles.h3, { marginBottom: 11 }]}>Product description</Text>
                    <Text style={globalStyles.p}>Mirdzumu stimulējošs koncentrāts no 9 izmeklētām un vērtīgām sausajām eļļām piešķir ādai veselīgu, starojošu un jauneklīgu izskatu.</Text>
                    <Text style={globalStyles.p}>Multivitamīnu-antioksidantu spēks no zilenes, avenes, dzērvenes sēklu eļļām ir sinerģiski sajaukts ar atdzīvinošu iedarbību no kazenes, melnās ķimenes, pētersīļa eļļām, ko visu kopā iekļauj ādu mitrinoša trijotne ar ķirbja, gurķa mētras un zelta hohobas sēklu eļļām.</Text>
                    <Text style={[globalStyles.link]}>Read More</Text>
                </View> */}
                {/* <Accordion data={accordionData} setAccordionData={setAccordionData} /> */}
                <SnapioFooter />
            </ScrollView>
            <NewSnapFooter />
        </View>
    )
}

const mapDispatchToProps = (dispatch) => ({
    addItem: (productId, productQuantity) => dispatch(addItem(productId, productQuantity)),
    getUserData: () => dispatch(getUserData())
})

const mapStateToProps = (state) => ({
    singleProduct: state.product.singleProduct
})
export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 16,
        width: '100%',
        backgroundColor: 'white',
    },
    companyLogo: {
        minWidth: 80,
        minHeight: 80,
        width: 80,
        height: 80,
        resizeMode: 'cover',
        borderRadius: 1000,
    },
    companyTitle: {
        marginTop: 16,
        marginBottom: 4,
        fontSize: 12,
        color: '#6C6C6C',
        lineHeight: 20,
        fontFamily: 'Pragmatica-Book',
        textAlign: 'center',
    },
    productTitle: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 34,
        lineHeight: 44,
        letterSpacing: -0.4,
        color: '#161616',
        textAlign: 'center',
        marginBottom: 24,
    },
    mainPrice: {
        color: '#161616',
        fontFamily: 'Pragmatica-Bold',
        fontSize: 22,
        lineHeight: 28,
    },
    optionTitle: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book',
        alignSelf: 'flex-start',
        marginBottom: 17,
        marginTop: 21,
    },
    infoLogo: {
        width: 24,
        height: 24,
        minWidth: 24,
        minHeight: 24,
        resizeMode: 'cover',
        marginRight: 8,
        borderRadius: 100,
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        marginBottom: 20,
    },
    infoTitle: {
        fontFamily: 'Pragmatica-Book',
        fontSize: 14,
        lineHeight: 20,
        color: '#161616'
    },
    sendBox: {
        marginRight: 8,
    },
    infoUnderline: {
        flexDirection: 'column',
        position: 'relative',
    },
    infoUnderlineLine: {
        height: 1,
        width: '100%',
        backgroundColor: '#161616',
        position: 'absolute',
        left: 0,
        bottom: 3,
    },
    descriptionContainer: {
        width: '100%',
        marginBottom: 12,
    }
})