import React, { useState } from 'react';
import { View, Text, StyleSheet } from 'react-native';

import i18n from 'i18n-js'

import FullScreenModal from "./fullScreenModal";

const SnapioFooter = () => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <View style={styles.container}>
            <Text style={styles.text}>{i18n.t("footer.powered_by_snapio")}</Text>
            <View style={styles.textContainer}>
                <Text style={[styles.text, { color: 'rgba(22, 22, 22, 0.5)' }]} onPress={() => { setModalVisible(!modalVisible) }}>{i18n.t("footer.terms_and_conditions")}</Text>
                <Text style={[styles.text, { color: 'rgba(22, 22, 22, 0.5)' }]}>{i18n.t("footer.privacy_policy")}</Text>
            </View>
            <FullScreenModal visible={modalVisible} modalTitle={i18n.t("footer.terms_of_use")} onPress={() => { setModalVisible(!modalVisible) }} />
        </View>
    )
}

export default SnapioFooter

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginTop: 10,
    },
    text: {
        paddingTop: 14,
        paddingBottom: 16,
        color: '#161616',
        fontFamily: 'Pragmatica-Book',
        textAlign: 'center',
    },
    textContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderTopWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.2)',
    }
})