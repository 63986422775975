import { POST_SMS, POST_FAILURE, POST_SUCCESS, CONFIRM_SMS, LOGOUT, LOGOUT_FAIL, LOGOUT_SUCCESS } from './login.types'
import store from '../store'
import axios from 'axios'
import { BASE_URL } from '../../api/api'
// User data
import { getUserData } from '../user/user.actions'

export function fetchSms(phone_number, navigation) {
    return (dispatch) => {
        dispatch(postSms())
        return (
            axios.post('/auth/verification', {
                "country_code": store.getState().selectCountry.countryCode.value.iso2,
                "phone_number": phone_number
            })
                .then(res => {
                    if (res.data.success) {
                        navigation.navigate('LoginStack', { screen: 'Login SMS', params: { phone_number } });
                        return dispatch(postSmsSuccess(res.data.payload))
                    }
                })
                .catch(err => dispatch(postSmsFailure()))
        )
    }
}

export function fetchPostConfirmSms(value, navigation) {
    return (dispatch) => {
        dispatch(postConfirmSms())
        return (
            axios.post('/auth/' + store.getState().login.payload.token + '/login', {
                "verification_code": value
            })
                .then(res => {
                    if (res.data.success) {
                        const userToken = res.data.payload.token;
                        localStorage.setItem('user-token', userToken);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + userToken;
                        dispatch(getUserData())

                        // There is no API for updating only email thats why always go to list page

                        // .then(() => {
                        //     if (store.getState().user.userData?.email) {
                        //         navigation.navigate('MyStack', { screen: 'List Product' })
                        //     } else {
                        //         navigation.navigate('LoginStack', { screen: 'Login Welcome' })
                        //     }
                        // })

                        navigation.navigate('MyStack', { screen: 'List Product' })
                        return dispatch(postConfirmSms(userToken));
                    }
                })
                .catch(err => dispatch(postSmsFailure()))
        )
    }
}

export function logout(navigation) {
    return (dispatch) => {
        return (
            axios.delete('/auth/session')
                .then((res) => {
                    localStorage.removeItem('user-token');
                    delete axios.defaults.headers.common['Authorization'];
                    navigation.navigate('LoginStack', { screen: 'Login' })
                    return dispatch(logoutSuccess())
                })
                .catch(err => dispatch(logoutFail()))
        )
    }
}

const logoutStart = () => {
    return {
        type: LOGOUT
    }
}
const logoutSuccess = () => {
    return {
        type: LOGOUT_SUCCESS,
        data
    }
}

const logoutFail = () => {
    return {
        type: LOGOUT_FAIL,
    }
}

function postSms() {
    return {
        type: POST_SMS
    }
}

function postSmsSuccess(data) {
    return {
        type: POST_SUCCESS,
        data
    }
}

function postConfirmSms(data) {
    return {
        type: CONFIRM_SMS,
        data
    }
}

function postSmsFailure() {
    return {
        type: POST_FAILURE
    }
}
