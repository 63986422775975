import React from 'react';
import { View, Image, Text, StyleSheet, TouchableOpacity } from 'react-native';

import { connect } from 'react-redux';
import { increaseQuantity, decreaseQuantity, deleteItem } from "../redux/cart/cart.actions";

import globalStyles, { colors } from "../assets/globalStyles";
import { TrashBin } from "./svg/SnapioImages";

const CartProductList = ({ data, increaseQuantity, decreaseQuantity, deleteItem }) => {
    const { items } = data;
    return (
        <View style={{ width: '100%' }}>
            <Text style={[globalStyles.h3, { marginTop: 24, marginBottom: 0 }]}>Products</Text>
            {
                items && items.map((item, index) => (
                    <View style={[
                        { width: '100%', flexDirection: 'row', paddingBottom: 24, borderBottomColor: 'rgba(22,22,22,0.1)', borderBottomWidth: 1, paddingTop: 24 },
                        index === 0 && { paddingTop: 18 },
                        index === data.length - 1 && { borderBottomWidth: 0 }
                    ]} key={index}>
                        <Image
                            style={{ height: 'auto', maxWidth: '25%', resizeMode: 'contain', flex: 1 }}
                            source={{ uri: `https://erp.test.snapio.shop${item.thumbnail}` }}
                        />
                        <View style={{ marginLeft: 16, justifyContent: 'space-between', flex: 2 }}>
                            <View style={{ flexDirection: 'column' }}>
                                <View>
                                    <Text style={[globalStyles.h4, { marginBottom: 0 }]}>{item.price}</Text>
                                    <Text style={{ fontFamily: 'Pragmatica-Bold', fontSize: 12, lineHeight: 16, color: colors.dark, marginTop: 4 }}>{item.title}</Text>
                                </View>
                                <View style={{ marginBottom: 16, marginTop: 8, flexDirection: 'row' }}>
                                    <Text style={{ fontFamily: 'Pragmatica-Book', fontSize: 12, lineHeight: 16, color: '#6C6C6C', flexDirection: 'row' }}>
                                        {item.addon_titles.map(addon => (addon + ', '))}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', width: '100%' }}>
                                <View>
                                    <Text style={{ fontFamily: 'Pragmatica-Book', fontSize: 12, lineHeight: 16, color: '#6C6C6C' }}>Quantity</Text>
                                    <Text style={[globalStyles.h4, { marginTop: 0, marginBottom: 0 }]}>{item.quantity}</Text>
                                </View>
                                <View style={{ flexDirection: 'row' }}>
                                    <TouchableOpacity onPress={() => {
                                        item.quantity === 1 ? deleteItem(item) : decreaseQuantity(item);
                                    }}>
                                        <View style={styles.countButton}>
                                            {item.quantity === 1 ? <TrashBin /> : <View style={styles.minus}></View>}
                                        </View>
                                    </TouchableOpacity>
                                    <TouchableOpacity
                                        style={{ marginLeft: 16 }}
                                        onPress={() => {
                                            increaseQuantity(item);
                                        }}
                                    >
                                        <View style={styles.countButton}>
                                            <View style={styles.minus}></View>
                                            <View style={styles.plus}></View>
                                        </View>
                                    </TouchableOpacity>
                                </View>
                            </View>
                        </View>
                    </View>
                ))
            }
        </View>
    )
}

const mapDispatchToProps = (dispatch) => {
    return ({
        decreaseQuantity: (item) => dispatch(decreaseQuantity(item)),
        increaseQuantity: (item) => dispatch(increaseQuantity(item)),
        deleteItem: (item) => dispatch(deleteItem(item))
    })
};

const mapStateToProps = (state) => ({
    data: state.cart.cartList
})

export default connect(mapStateToProps, mapDispatchToProps)(CartProductList);

const styles = StyleSheet.create({
    countButton: {
        width: 40,
        height: 40,
        backgroundColor: '#FFFFFF',
        borderRadius: 100,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        borderWidth: 1,
        borderColor: "#D9D9D9",
    },
    minus: {
        width: 20,
        height: 2,
        backgroundColor: '#602C8B',
        position: 'absolute'
    },
    plus: {
        height: 20,
        width: 2,
        backgroundColor: '#602C8B',
        position: 'absolute',
    },
})