import React, { useState, memo } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';

const TestCounter = () => {
    const [count, setCount] = useState(0);

    return (
        <View>
            <Text>{count}</Text>
            <TouchableOpacity onPress={() => { setCount(count + 1) }}>
                <Text>+</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { setCount(count - 1) }}>
                <Text>-</Text>
            </TouchableOpacity>
        </View>
    )
}
const TestCounter1 = memo(({ count1, setCount1 }) => {
    return (
        <View>
            <Text>{count1}</Text>
            <TouchableOpacity onPress={() => { setCount1(count1 + 1) }}>
                <Text>+</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { setCount1(count1 - 1) }}>
                <Text>-</Text>
            </TouchableOpacity>
        </View>
    )
})
const TestCounter2 = ({ count2, setCount2 }) => {
    return (
        <View>
            <Text>{count2}</Text>
            <TouchableOpacity onPress={() => { setCount2(count2 + 2) }}>
                <Text>+</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { setCount2(count2 - 2) }}>
                <Text>-</Text>
            </TouchableOpacity>
        </View>
    )
}

const TestPage = (props) => {
    const [count, setCount] = useState(0);
    const [count1, setCount1] = useState(1);
    const [count2, setCount2] = useState(2);

    return (
        <View>
            <Text>{count}</Text>
            <View>
                <TouchableOpacity onPress={() => { setCount(count + 1) }}>
                    <Text>+</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { setCount(count - 1) }}>
                    <Text>-</Text>
                </TouchableOpacity>
            </View>
            <View style={{ borderBottomColor: 'black', width: '100%', borderBottomWidth: 1 }}></View>
            <TestCounter />
            <TestCounter1 count1={count1} setCount1={setCount1} />
            <TestCounter2 count2={count2} setCount2={setCount2} />
        </View>
    )
}

export default TestPage