import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, } from 'react-native';

import { connect } from 'react-redux';
import { selectPaymentMethod } from '../redux/payment/payment.actions';

const OptionListPayments = ({ paymentMethods, selectPaymentMethod }) => {

    return (
        <View style={{ width: '100%' }}>
            {
                paymentMethods.map((item, index) => {
                    return (
                        <View key={index} style={index === 0 ? styles.optionContainerTopBotBorder : styles.optionContainer}>
                            <TouchableOpacity
                                style={styles.touchContainer}
                                onPress={() => selectPaymentMethod(item)}
                            >
                                <View style={item.selected || item.is_default ? styles.circleSelected : styles.circle}>
                                    {item.selected || item.is_default ? <View style={styles.innerCircle}></View> : null}
                                </View>
                                <View>
                                    <Text style={styles.optionTitle}>{item.title}</Text>
                                    <Text style={styles.optionPrice}>{item.price}</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    )
                })
            }
        </View>
    )
}

const mapStateToProps = (state) => ({
    paymentMethods: state.paymentMethods.cards,
})

const mapDispatchToProps = (dispatch) => ({
    selectPaymentMethod: (item) => dispatch(selectPaymentMethod(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(OptionListPayments);

const styles = StyleSheet.create({
    optionContainer: {
        width: '100%',
        paddingTop: 21,
        paddingBottom: 18,
        borderBottomWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
    },
    optionContainerTopBotBorder: {
        width: '100%',
        paddingTop: 21,
        paddingBottom: 18,
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderColor: 'rgba(22, 22, 22, 0.1)',
    },
    touchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    optionHeader: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book',
        alignSelf: 'flex-start',
        marginBottom: 17,
        marginTop: 21,
    },
    circle: {
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        borderRadius: 100,
        borderWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
    },
    circleSelected: {
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        borderRadius: 100,
        borderWidth: 1,
        backgroundColor: '#602C8B',
        borderColor: '#602C8B',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
    },
    innerCircle: {
        width: 16,
        height: 16,
        minWidth: 16,
        minHeight: 16,
        backgroundColor: '#F6F6F6',
        borderRadius: 100,
    },
    optionTitle: {
        color: '#161616',
        fontSize: 16,
        lineHeight: 24,
        fontFamily: 'Pragmatica-Book'
    },
    optionPrice: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book'
    }
})