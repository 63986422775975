import React from 'react';
import { Text, TouchableOpacity, StyleSheet, View, Image } from 'react-native';

import globalStyles from "../assets/globalStyles";

import { ArrowRight } from "./svg/SnapioImages";

const Accordion = ({ data, setAccordionData }) => {

    const toggleShow = (key) => {
        setAccordionData(data.map((item, index) => {
            if (index === key) {
                item.show = !item.show;
                return item;
            }
            return item;
        }))
    }

    return (
        <View style={styles.container}>
            {
                data.map((item, index) => (
                    <View stytle={styles.accordionItemContainer} key={index}>
                        <TouchableOpacity style={
                            index === 0 ?
                                [styles.titleWrap, { borderTopWidth: 1 }, item.show && { borderBottomWidth: 0 }]
                                :
                                [styles.titleWrap, item.show && { borderBottomWidth: 0 }]
                        } onPress={() => toggleShow(index)}>
                            <Text style={[globalStyles.h4, styles.title]}>{item.title}</Text>
                            <ArrowRight style={item.show && {transform: [{rotate: "90deg"}]}} />
                        </TouchableOpacity>
                        {
                            item.show &&
                            <View style={styles.content}>
                                <Text style={globalStyles.p}>{item.data}</Text>
                            </View>
                        }
                    </View>
                )
                )
            }
        </View>
    )
}

export default Accordion;

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginVertical: 12,
    },
    titleWrap: {
        paddingTop: 17,
        paddingBottom: 19,
        borderColor: 'rgba(22, 22, 22, 0.1)',
        borderBottomWidth: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    title: {
        textAlign: 'left',
        marginBottom: 0,
    },
    content: {
        borderBottomWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
    }
})