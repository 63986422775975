import React, { useState, useRef, useEffect } from 'react'
import { View, Text, TouchableOpacity, TextInput, StyleSheet } from 'react-native'

import i18n from 'i18n-js';

import ButtonPrimary from '../../components/buttonPrimary'

import { BackButton } from '../../components/svg/SnapioImages'
import globalStyles, { colors } from '../../assets/globalStyles'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { fetchPostConfirmSms } from '../../redux/login/login.action'

import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from 'react-native-confirmation-code-field';

const styles = StyleSheet.create({
  codeFieldRoot: { marginTop: 20 },
  cell: {
    width: 40,
    height: 40,
    lineHeight: 38,
    fontSize: 24,
    borderBottomWidth: 1,
    borderColor: colors.seperator,
    textAlign: 'center',
  },
  cellText: {
    lineHeight: 38,
    fontSize: 24,
    color: colors.dark
  },
  focusCell: {
    borderColor: colors.brand1,
  },
});

const CELL_COUNT = 5;

const LoginSmsPage = ({ navigation, fetchPostConfirmSms, route }) => {
  const { phone_number } = route.params
  const [value, setValue] = useState('');
  const [isFocused, setFocus] = useState(false);
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });
  const inputEl = useRef(null)
  const focusOnInput = () => {
    inputEl.current.focus();
  }

  useEffect(() => {
    focusOnInput()
  }, [])

  return (
    <View style={{ flex: 1 }}>
      <View style={globalStyles.headerContainer}>
        <TouchableOpacity style={globalStyles.headerBackButton} onPress={() => navigation.goBack()}><BackButton /></TouchableOpacity>
        <Text style={globalStyles.headerText}>{i18n.t("login.log_in")}</Text>
      </View>
      <View style={{ flex: 2, paddingHorizontal: 20 }}>
        <Text style={[
          globalStyles.highlight,
          { textAlign: 'center', fontSize: 21, lineHeight: 32, marginTop: 26 }
        ]}>
          {`${i18n.t("login.enter_sms")} ${phone_number}`}
        </Text>
        <View style={{ marginTop: 40, width: '100%', maxWidth: 200, marginLeft: 'auto', marginRight: 'auto' }}>
          {/* <TextInput
            style={globalStyles.inputField}
            value={value}
            ref={inputEl}
            onChangeText={(text) => setValue(text)}
          /> */}
        </View>
        <View style={{ maxWidth: 300, width: '100%', marginLeft: 'auto', marginRight: 'auto' }}>
          <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
            onPress={focusOnInput}
          >
            <TextInput
              ref={inputEl}
              value={value}
              style={{ height: 0, width: 0, position: 'absolute', }}
              onBlur={() => {
                setFocus(false)
                if (value.length === CELL_COUNT - 1) {
                  fetchPostConfirmSms(inputEl.current.value, navigation)
                }
              }}
              onFocus={() => {
                setFocus(true)
              }}
              onChangeText={(text) => {
                setValue(text);
                console.log(text);
                if (text.length === CELL_COUNT) {
                  inputEl.current.blur()
                }
              }}
            />
            {
              Array.from({ length: CELL_COUNT }, (_, index) =>
                <TouchableOpacity style={[styles.cell, index === value.length && isFocused && styles.focusCell]} key={index} onPress={focusOnInput}>
                  <Text
                    style={styles.cellText}
                    onPress={focusOnInput}
                  >
                    {value.charAt(index)}
                  </Text>
                </TouchableOpacity>)
            }
          </View>
        </View>
        <ButtonPrimary
          style={{ marginTop: 'auto' }}
          title={i18n.t("login.confirm_code")}
          onPress={
            () => fetchPostConfirmSms(value, navigation)
            // navigation.navigate('LoginStack', { screen: 'Login SMS' })
          }
        />
      </View>
    </View>
  )
}

const mapStateToProps = (state) => ({
  login: state.login.payload
})

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators({ fetchPostConfirmSms }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginSmsPage)