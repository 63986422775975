export const GET_DELIVERY_ADDRESSES = 'GET_DELIVERY_ADDRESSES';
export const GET_DELIVERY_ADDRESSES_SUCCESS = 'GET_DELIVERY_ADDRESSES_SUCCESS';
export const GET_DELIVERY_ADDRESSES_FAIL =  "GET_DELIVERY_ADDRESSES_FAIL";

export const ADD_DELIVERY_ADDRESS = 'ADD_DELIVERY_ADDRESS';
export const ADD_DELIVERY_ADDRESS_SUCCESS = 'ADD_DELIVERY_ADDRESS_SUCCESS';
export const ADD_DELIVERY_ADDRESS_FAIL = 'ADD_DELIVERY_ADDRESS_FAIL';

export const SET_ADDRESS_DEFAULT = 'SET_ADDRESS_DEFAULT';

export const DELETE_ADDRESS = 'DELETE_ADDRESS';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';

export const SET_MAP_LATLNG = 'SET_MAP_LATLNG';