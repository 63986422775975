import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Modal, Platform, ScrollView, TextInput, Dimensions } from 'react-native';
// import CheckBox from '@react-native-community/checkbox';
import i18n from 'i18n-js'
import ModalWeb from 'modal-react-native-web';
import MapView, { Marker } from 'react-native-maps';
import { CheckBox } from 'react-native-elements'

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLatLng, addDeliveryAddress, clearError } from '../redux/delivery/delivery.actions'

import ButtonPrimary from "./buttonPrimary";
import ErrorField from './errorField'
import ButtonSecondary from './buttonSecondary'

import { CloseModal, CheckboxTick } from "./svg/SnapioImages";

import globalStyles, { colors } from "../assets/globalStyles";

const AddressModal = ({ modalTitle, visible, onPress, addDeliveryAddress, edit, clearError, deliveryAddress, getLatLng }) => {
    const { mapLatLng, mapLatLng: { country_code } } = deliveryAddress;
    const [newAddress, setNewAddress] = useState({
        county: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        postal_code: '',
        additional_information: '',
        is_default: false,
        lat: 56.96881850000000000000,
        lng: 24.23749820000000000000
    })

    const error = deliveryAddress.errorFields.errors

    const [toggleCheckBox, setToggleCheckBox] = useState(false)

    const clearNewAddress = () => {
        setNewAddress({
            county: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            postal_code: '',
            additional_information: '',
            is_default: false,
        })
    }

    useEffect(() => {
        clearNewAddress();
        // if (edit) { getCurrentAddress(edit) }
    }, [])

    useEffect(() => {
        console.log('new lat lng', mapLatLng)
        const { lat: latitude = 56.96881850000000000000, lng: longitude = 24.23749820000000000000 } = mapLatLng;
        setMarker({ latitude, longitude })
        setNewAddress({ ...newAddress, lat: latitude, lng: longitude, country_code })
    }, [mapLatLng])

    const getCurrentAddress = (id) => {
        deliveryAddress.addresses.map(item => {
            if (item.id === id) {
                setNewAddress({
                    county: item.county,
                    address_line_1: item.address_line_1,
                    address_line_2: item.address_line_2,
                    city: item.city,
                    postal_code: item.postal_code,
                    additional_information: item.additional_information,
                    is_default: item.is_default,
                })
            }
        })
    }

    const [marker, setMarker] = useState({
        latitude: 56.96881850000000000000,
        longitude: 24.23749820000000000000
    })

    return (
        <View>
            {
                Platform.OS === 'web' ?
                    <ModalWeb
                        visible={visible}
                        ariaHideApp={false}
                    >
                        <View style={styles.headerContainer}>
                            <Text style={styles.headerText}>{modalTitle}</Text>
                            <TouchableOpacity style={styles.close} onPress={() => {
                                clearError();
                                onPress();
                            }}><CloseModal /></TouchableOpacity>
                        </View>
                        <ScrollView style={styles.container}>
                            <View style={{ marginTop: 17 }}>
                                <Text style={globalStyles.inputLabel}>{i18n.t("address.address")}</Text>
                                <TextInput
                                    style={[globalStyles.inputField, deliveryAddress.error && error.address_line_1 && globalStyles.inputFieldError]}
                                    value={newAddress.address_line_1 || ''}
                                    placeholder={i18n.t("address.address")}
                                    onChangeText={(text) => {
                                        setNewAddress({
                                            ...newAddress,
                                            address_line_1: text
                                        })
                                    }}
                                    onBlur={() => {
                                        getLatLng(newAddress)
                                    }}
                                />
                                {deliveryAddress.error && error.address_line_1 && <ErrorField message={error.address_line_1} />}
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={newAddress.address_line_2 || ''}
                                    placeholder={i18n.t("address.flat_number")}
                                    onChangeText={(text) => {
                                        setNewAddress({
                                            ...newAddress,
                                            address_line_2: text
                                        })
                                    }}
                                    onBlur={() => getLatLng(newAddress)}
                                />
                                <Text style={globalStyles.inputLabel}>{i18n.t("address.town_city")}</Text>
                                <TextInput
                                    style={[globalStyles.inputField, deliveryAddress.error && error.city && globalStyles.inputFieldError]}
                                    value={newAddress.city || ''}
                                    placeholder={i18n.t("address.town_city")}
                                    onChangeText={(text) => {
                                        setNewAddress({
                                            ...newAddress,
                                            city: text
                                        })
                                    }}
                                    onBlur={() => getLatLng(newAddress)}
                                />
                                {deliveryAddress.error && error.city && <ErrorField message={error.city} />}
                                <Text style={globalStyles.inputLabel}>{i18n.t("address.county")}</Text>
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={newAddress.county || ''}
                                    placeholder={i18n.t("address.county")}
                                    onChangeText={(text) => {
                                        setNewAddress({
                                            ...newAddress,
                                            county: text
                                        })
                                    }}
                                    onBlur={() => getLatLng(newAddress)}
                                />
                                <Text style={globalStyles.inputLabel}>{i18n.t("address.post_code")}</Text>
                                <TextInput
                                    style={[globalStyles.inputField, deliveryAddress.error && error.postal_code && globalStyles.inputFieldError]}
                                    value={newAddress.postal_code || ''}
                                    placeholder={i18n.t("address.post_code")}
                                    onChangeText={(text) => {
                                        setNewAddress({
                                            ...newAddress,
                                            postal_code: text
                                        })
                                    }}
                                    onBlur={() => getLatLng(newAddress)}
                                />
                                {deliveryAddress.error && error.postal_code && <ErrorField message={error.postal_code} />}
                                <Text style={globalStyles.inputLabel}>{i18n.t("address.additional_information")}</Text>
                                <TextInput
                                    style={[globalStyles.inputField, { height: 'auto', paddingTop: 8 }]}
                                    multiline={true}
                                    numberOfLines={5}
                                    value={newAddress.additional_information || ''}
                                    placeholder={i18n.t("address.additional_information")}
                                    onChangeText={(text) => {
                                        setNewAddress({
                                            ...newAddress,
                                            additional_information: text
                                        })
                                    }}
                                />
                                <View style={{ height: 200 }}>
                                    <MapView
                                        initialRegion={marker}
                                        region={marker}
                                        showTraffic={false}
                                        showsIndoors={false}
                                        zoomControlEnabled={false}
                                    >
                                        <MapView.Marker
                                            draggable
                                            coordinate={marker}
                                            onDragEnd={e => {
                                                setMarker({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })
                                                setNewAddress({
                                                    ...newAddress,
                                                    lat: e.latLng.lat(),
                                                    lng: e.latLng.lng()
                                                })
                                            }}
                                        />
                                    </MapView>
                                </View>
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    <CheckBox
                                        center
                                        title={i18n.t("address.set_default")}
                                        checked={newAddress.is_default}
                                        uncheckedIcon={<View style={{
                                            width: 32,
                                            height: 32,
                                            backgroundColor: colors.lightest,
                                            borderWidth: 1,
                                            borderColor: '#D9D9D9',
                                            borderRadius: 6,
                                        }}></View>}
                                        checkedIcon={
                                            <View style={{
                                                width: 32,
                                                height: 32,
                                                backgroundColor: colors.brand1,
                                                borderWidth: 1,
                                                borderColor: colors.brand1,
                                                borderRadius: 6,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            }}><CheckboxTick /></View>
                                        }
                                        containerStyle={[globalStyles.checkboxContainer]}
                                        textStyle={[globalStyles.checkboxText]}
                                        onPress={() => {
                                            setNewAddress({
                                                ...newAddress,
                                                is_default: !newAddress.is_default
                                            })
                                            // setToggleCheckBox(!toggleCheckBox)
                                        }}
                                    />
                                </View>
                                <ButtonPrimary title={i18n.t("address.save")} onPress={() => {
                                    addDeliveryAddress(newAddress, onPress)
                                        .then(({ type }) => type === "ADD_DELIVERY_ADDRESS_SUCCESS" && clearNewAddress())
                                    // clearNewAddress()
                                }} />
                                {edit && <Text style={[globalStyles.p], { textAlign: 'center', opacity: 0.5, }}>{i18n.t("address.remove_card")}</Text>}
                            </View>
                        </ScrollView>
                    </ModalWeb>
                    :
                    <Modal
                        visible={visible}
                    >
                        <View style={styles.headerContainer}>
                            <Text style={styles.headerText}>{edit ? 'Edit card details' : modalTitle}</Text>
                            <TouchableOpacity style={styles.close} onPress={onPress}><CloseModal /></TouchableOpacity>
                        </View>
                        <ScrollView style={styles.container}>
                            <View style={{ marginTop: 17 }}>
                                <Text style={globalStyles.inputLabel}>Name Surname</Text>
                                <TextInput
                                    // TODO: Add correct types 
                                    style={globalStyles.inputField}
                                    value={newAddress.fullName}
                                    onChangeText={(text) => setNewAddress(newAddress.fullName = text)}
                                />
                                <Text style={globalStyles.inputLabel}>Mobile</Text>
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={newAddress.mobile}
                                    onChangeText={(text) => setNewAddress(newAddress.mobile = text)}
                                />
                                <Text style={globalStyles.inputLabel}>County</Text>
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={newAddress.county}
                                    onChangeText={(text) => setNewAddress(newAddress.county = text)}
                                />
                                <Text style={globalStyles.inputLabel}>Address</Text>
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={newAddress.address}
                                    onChangeText={(text) => setNewAddress(newAddress.address = text)}
                                />
                                <Text style={globalStyles.inputLabel}>Town / City</Text>
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={newAddress.city}
                                    onChangeText={(text) => setNewAddress(newAddress.town = text)}
                                />
                                <Text style={globalStyles.inputLabel}>Post Code</Text>
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={newAddress.post_code}
                                    onChangeText={(text) => setNewAddress(newAddress.post_code = text)}
                                />
                                {/*  TODO: need to add action 'add to store' */}
                                {edit &&
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        {/* TODO: Need to create custom checkbox to fit style */}
                                        {/* <CheckBox
                                            disabled={false}
                                            value={toggleCheckBox}
                                            onValueChange={(newValue) => setToggleCheckBox(newValue)}
                                            tintColor='#000000'
                                        />
                                        <Text style={[colors.checkboxText]}>Set as your default address</Text> */}
                                    </View>}
                                <ButtonPrimary title="SAVE" />
                                {edit && <Text style={[globalStyles.p], { textAlign: 'center', opacity: 0.5, }}>Remove card</Text>}
                            </View>
                        </ScrollView>
                    </Modal>
            }
        </View>
    )
}

const mapStateToProps = (state) => ({
    deliveryAddress: state.deliveryAddress,
})

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({ getLatLng }, dispatch),
    ...bindActionCreators({ addDeliveryAddress }, dispatch),
    clearError: () => dispatch(clearError()),
    editCurrentUser: (userData, text) => dispatch(editCurrentUser(userData, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddressModal)


const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        width: '100%',
        backgroundColor: 'white',
    },
    headerContainer: {
        paddingTop: 16,
        paddingBottom: 17,
        borderBottomWidth: 1,
        borderColor: 'rgba(22,22,22,0.2)',
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: 20,
        textAlign: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 16,
        lineHeight: 22,
        color: '#161616',
    },
    close: {
        position: 'absolute',
        right: 20,
        alignSelf: 'center'
    },
    mapContainer: {
        flex: 1,
        backgroundColor: '#fff',
        alignItems: 'center',
        justifyContent: 'center',
    },
    map: {
        width: Dimensions.get('window').width,
        height: Dimensions.get('window').height,
    },
})