import { POST_SMS, POST_FAILURE, POST_SUCCESS, CONFIRM_SMS, LOGOUT_FAIL, LOGOUT_SUCCESS } from './login.types';

const initialState = {
    payload: [],
    userToken: '',
    isFetching: false,
    error: false
}

const loginReducer = (state = initialState, action) => {

    switch (action.type) {
        case POST_SMS:
            return {
                ...state,
                isFetching: true
            }
        case POST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                payload: action.data
            }
        case CONFIRM_SMS:
            return {
                ...state,
                isFetching: false,
                userToken: action.data
            }
        case POST_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case LOGOUT_SUCCESS:
            return {
                ...state,
                userToken: '',
            }
        case LOGOUT_FAIL:
            return {
                ...state,
                error: true
            }
        default:
            return state
    }
}

export default loginReducer
