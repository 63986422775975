import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';
import { connect } from 'react-redux';

const OptionList = ({ productItems, setData, title, productOptions }) => {

    const pressHandler = (key) => {
        setData(productItems.map((item, index) => {
            item.selected = false;
            if (key === index) {
                item.selected = !item.selected
                return item;
            }
            return item;
        }))
    }

    const optionHandler = (key) => {
        setOptionItems(productItems.map((item, index) => {
            item.selected = false;
            if (key === index) {
                item.selected = !item.selected
                return item;
            }
            return item;
        }))
    }

    const [optionItems, setOptionItems] = useState(
        productItems ?
            productItems.map(item => {
                item.label = item.title + ' - ' + item.price;
                item.value = item.price;
                return item
            }) :
            []
    )

    useEffect(() => {
        productItems ?
            setData(
                productItems.map((item, index) => {
                    if (index === 0) {
                        item.selected = true
                        return item;
                    }
                    return item;
                }))
            :
            null
    }, [])

    return (
        <View style={{ width: '100%' }}>
            <Text style={styles.optionHeader}>{title}</Text>
            {
                productItems && productItems.length < 3
                    ?
                    productItems.map((item, index) => {
                        return (
                            <View key={index} style={index === 0 ? styles.optionContainerTopBotBorder : styles.optionContainer}>
                                <TouchableOpacity
                                    style={styles.touchContainer}
                                    onPress={() => pressHandler(index)}
                                >
                                    <View style={item.selected ? styles.circleSelected : styles.circle}>
                                        {item.selected ? <View style={styles.innerCircle}></View> : null}
                                    </View>
                                    <View>
                                        <Text style={styles.optionTitle}>{item.title}</Text>
                                        <Text style={styles.optionPrice}>{item.price}</Text>
                                    </View>
                                </TouchableOpacity>
                            </View>
                        )
                    })
                    :
                    <DropDownPicker
                        items={optionItems}
                        containerStyle={{ height: 40 }}
                        style={{
                            backgroundColor: '#EBEBEB',
                            borderRadius: 8,
                        }}
                        containerStyle={{
                            height: 48
                        }}
                        itemStyle={{
                            justifyContent: 'flex-start'
                        }}
                        dropDownStyle={{ backgroundColor: '#EBEBEB' }}
                        onChangeItem={(item, index) => {
                            optionHandler(index);
                        }}
                    />
            }
        </View>
    )
}

const mapStateToProps = (state) => ({
    productOptions: state.product.singleProduct.addons,
})

export default connect(mapStateToProps)(OptionList);

const styles = StyleSheet.create({
    optionContainer: {
        width: '100%',
        paddingTop: 21,
        paddingBottom: 18,
        borderBottomWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
    },
    optionContainerTopBotBorder: {
        width: '100%',
        paddingTop: 21,
        paddingBottom: 18,
        borderBottomWidth: 1,
        borderTopWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
    },
    touchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    optionHeader: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book',
        alignSelf: 'flex-start',
        marginBottom: 17,
        marginTop: 21,
    },
    circle: {
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        borderRadius: 100,
        borderWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
    },
    circleSelected: {
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        borderRadius: 100,
        borderWidth: 1,
        backgroundColor: '#602C8B',
        borderColor: '#602C8B',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
    },
    innerCircle: {
        width: 16,
        height: 16,
        minWidth: 16,
        minHeight: 16,
        backgroundColor: '#F6F6F6',
        borderRadius: 100,
    },
    optionTitle: {
        color: '#161616',
        fontSize: 16,
        lineHeight: 24,
        fontFamily: 'Pragmatica-Book'
    },
    optionPrice: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book'
    }
})