export const selectAddon = (singleProduct, data) => {
    const { addonListId, addonOptionId } = data

    singleProduct.addons.map(addons => {
        if (addons.id === addonListId) {
            addons.options.map(option => {
                option.selected = false;
                if (option.id === addonOptionId) {
                    option.selected = true;
                    return option;
                }
                return option
            })
        }
    })
    
    return { ...singleProduct }
}

export const setSelect = (singleProduct) => {
    singleProduct.addons.map(addons => {
        addons.options.map(option => {
            option.selected = false;
            option.label = option.title;
            option.value = option.id;
            return option
        })
    })
    return { ...singleProduct }
}