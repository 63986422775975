import { StyleSheet, StatusBar } from 'react-native';
import { color } from 'react-native-reanimated';

export const colors = {
    dark: '#161616',
    lightest: 'white',
    light: '#F6F6F6',
    tone4: '#6C6C6C',
    tone7: '#EBEBEB',
    seperator: 'rgba(22, 22, 22, 0.1)',
    primary: '#F27121',
    checkboxText: '#2E414D',
    brand1: '#602C8B',
    error: '#ED1C24',
    errorBackground: '#FFDADC',
}

const globalStyles = StyleSheet.create({
    link: {
        color: '#0D72FF',
        fontSize: 16,
        lineHeight: 24,
        fontFamily: 'Pragmatica-Book'
    },
    p: {
        color: colors.dark,
        fontSize: 16,
        lineHeight: 24,
        textAlign: 'left',
        fontFamily: 'Pragmatica-Book',
        marginBottom: 20,
    },
    h1: {
        color: colors.dark,
        fontSize: 45,
        lineHeight: 63,
        fontFamily: 'Pragmatica-Bold',
        marginBottom: 23,
    },
    h2: {
        color: colors.dark,
        fontSize: 34,
        lineHeight: 44,
        fontFamily: 'Pragmatica-Bold',
        letterSpacing: -0.4,
        marginBottom: 23,
    },
    h3: {
        color: colors.dark,
        fontSize: 22,
        lineHeight: 32,
        fontFamily: 'Pragmatica-Bold',
        letterSpacing: -0.4,
        marginBottom: 23,
    },
    h4: {
        color: colors.dark,
        fontSize: 16,
        lineHeight: 22,
        fontFamily: 'Pragmatica-Bold',
        marginBottom: 23,
    },
    highlight: {
        color: colors.dark,
        fontSize: 21,
        lineHeight: 32,
        fontFamily: 'Pragmatica-Book',
    },
    tag: {
        color: colors.tone4,
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book',
    },
    tightP: {
        color: colors.dark,
        lineHeight: 20,
        fontSize: 14,
        fontFamily: 'Pragmatica-Book',
    },
    inputField: {
        height: 48,
        backgroundColor: '#EBEBEB',
        borderRadius: 8,
        color: '#2E414D',
        paddingHorizontal: 16,
        fontFamily: 'Pragmatica-Book',
        lineHeight: 22,
        fontSize: 16,
        marginBottom: 15,
    },
    inputLabel: {
        color: '#77838C',
        fontFamily: 'Pragmatica-Book',
        fontSize: 14,
        lineHeight: 20,
        marginBottom: 1,
    },
    inputError: {
        color: colors.error,
        fontFamily: 'Pragmatica-Book',
        fontSize: 14,
        lineHeight: 20,
        margin: 0,
    },
    inputFieldError: {
        borderWidth: 1,
        borderColor: colors.error,
        backgroundColor: colors.errorBackground
    },
    headerContainer: {
        flex: 0,
        width: '100%',
        minHeight: 56,
        flexDirection: 'row',
        paddingHorizontal: 20,
        paddingVertical: 16,
        paddingTop: 16 + StatusBar.currentHeight,
        borderBottomWidth: 1,
        justifyContent: 'center',
        borderColor: 'rgba(22, 22, 22, 0.1)',
        backgroundColor: 'white',
    },
    headerText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 16,
        lineHeight: 22,
        color: '#161616',
    },
    headerBackButton: {
        position: 'absolute',
        left: 20,
        alignSelf: 'flex-end',
        bottom: 15,
    },
    headerCloseButton: {
        position: 'absolute',
        right: 20,
        alignSelf: 'flex-end',
        bottom: 15,
    },
    card: {
        shadowColor: 'rgb(21,41,53)',
        shadowOpacity: 0.4,
        shadowRadius: 4,
        shadowOffset: { width: 0, height: 2 },
        borderRadius: 8,
        borderColor: colors.light,
        backgroundColor: colors.lightest,
        borderWidth: 1,
    },
    checkboxText: {
        color: colors.checkboxText,
        fontFamily: 'Pragmatica-Book',
        fontSize: 16,
        lineHeight: 22,
        marginLeft: 8,
        fontWeight: "normal",
    },
    checkboxContainer: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        paddingLeft: 0,
        marginLeft: 0,
    },
})

export default globalStyles;