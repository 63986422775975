import React, { useState } from "react";

import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    TextInput,
    Image,
} from 'react-native';

import i18n from 'i18n-js'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setCardAsDefault, deleteCard } from "../redux/payment/payment.actions";

import ButtonSecondary from "../components/buttonSecondary";
import AddPaymentModal from '../components/addNewPaymentModal'

import { BackButton, MasterCard } from "../components/svg/SnapioImages";

import globalStyles, { colors } from "../assets/globalStyles";

const PaymentMethodsPage = ({ paymentMethods, setCardAsDefault, setDefault, navigation, deleteCard }) => {

    const [paymentModal, setPaymentModal] = useState({
        toggle: false,
        edit: false
    });

    const togglePaymentModal = (editVal = false) => {
        setPaymentModal({
            ...paymentModal,
            toggle: !paymentModal.toggle,
            edit: editVal,
        });
    }

    return (
        <View style={{ flex: 1, overflow: "hidden" }}>
            <View style={globalStyles.headerContainer}>
                <TouchableOpacity style={globalStyles.headerBackButton} onPress={() => navigation.goBack()}><BackButton /></TouchableOpacity>
                <Text style={globalStyles.headerText}>{i18n.t("payment.payment_methods")}</Text>
            </View>
            <View style={{
                flex: 2,
            }}>
                <ScrollView
                    contentContainerStyle={[styles.container, { marginTop: 24 }]}
                // style={[styles.container, { marginTop: 24 }]}
                >
                    {
                        paymentMethods.map((item, index) => {
                            return (
                                <View key={index} style={[globalStyles.card, { marginBottom: 16, marginHorizontal: 20 }]}>
                                    <View style={{
                                        paddingTop: 16,
                                        paddingHorizontal: 16,
                                        paddingBottom: 24,
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                        <MasterCard style={{ marginRight: 16 }} />
                                        <View>
                                            {/* TODO: Add imagefrom api */}
                                            {/* <Text style={[globalStyles.p, { marginBottom: 0 }]}>Mastercard Gold</Text>
                                    <Text style={[globalStyles.tag]}>(9988) Exp. 02/23 ZANE HELBERGA </Text> */}
                                            <Text style={[globalStyles.p, { marginBottom: 0 }]}>{item.title}</Text>
                                        </View>
                                    </View>
                                    <View style={[
                                        {
                                            paddingHorizontal: 16,
                                            paddingTop: 12,
                                            paddingBottom: 12,
                                            borderTopColor: colors.seperator,
                                            borderTopWidth: 1,
                                        },
                                        item.is_default ?
                                            {
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }
                                            : null
                                    ]}>
                                        <TouchableOpacity onPress={() => { deleteCard(item.id) }}>
                                            <Text style={[globalStyles.link]}>{i18n.t("delete")}</Text>
                                        </TouchableOpacity>
                                        {
                                            item.is_default
                                                ?
                                                <Text style={[globalStyles.tag]}>{i18n.t("payment.default")}</Text>
                                                :
                                                <TouchableOpacity onPress={() => setCardAsDefault(item.id)}>
                                                    <Text style={[globalStyles.link, { marginTop: 12 }]}>{i18n.t("payment.set_default")}</Text>
                                                </TouchableOpacity>
                                        }
                                    </View>
                                </View>
                            )
                        })
                    }
                    <AddPaymentModal
                        modalTitle={i18n.t("payment.payment_modal_title")}
                        visible={paymentModal.toggle}
                        edit={paymentModal.edit}
                        onPress={() => {
                            togglePaymentModal();
                        }} />
                </ScrollView>
                <ButtonSecondary title={i18n.t("payment.add_new_card")} style={{
                    marginTop: 'auto',
                    marginHorizontal: 20,
                    width: 'auto',
                }} onPress={() => {
                    togglePaymentModal();
                }} />
            </View>
        </View>
    )
}

const mapStateToProps = (state) => ({
    paymentMethods: state.paymentMethods.cards,
})

const mapDispatchToProps = (dispatch) => ({
    deleteCard: (card_id) => dispatch(deleteCard(card_id)),
    setCardAsDefault: (card_id) => dispatch(setCardAsDefault(card_id))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodsPage)


const styles = StyleSheet.create({
    container: {
        width: '100%',
        flex: 2,
    },
})