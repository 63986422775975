import { FETCH_COUNTRIES, FETCH_COUNTRIES_FAILURE, FETCH_COUNTRIES_SUCCESS } from './api.types';

const initialState = {
    countries: [],
    isFetching: false,
    error: false
}

const countriesReducer = (state = initialState, action) => {

    switch(action.type) {
        case FETCH_COUNTRIES:
            return {
                ...state,
                isFetching: true
            }
        case FETCH_COUNTRIES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                countries: action.data
            }
        case FETCH_COUNTRIES_FAILURE:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        default:
            return state
    }
}

export default countriesReducer