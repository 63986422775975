import * as React from 'react';
import { Text, TouchableOpacity, StyleSheet } from 'react-native';

const ButtonPrimary = ({ onPress, width, paddingLeft, paddingRight, marginTop, marginBottom, title, paddingBottom, paddingTop, style, textStyle }) => (
    <TouchableOpacity
        style={[{
            backgroundColor: '#F27121',
            color: '#F2F6F7',
            letterSpacing: 1.5,
            fontFamily: 'Pragmatica-Bold',
            fontSize: 14,
            lineHeight: 20,
            textAlign: 'center',
            paddingTop: (paddingTop || paddingTop === 0) ? paddingTop : 19,
            paddingBottom: (paddingBottom || paddingBottom === 0) ? paddingBottom : 17,
            paddingLeft: (paddingLeft || paddingLeft === 0) ? paddingLeft : 8,
            paddingRight: (paddingRight || paddingRight === 0) ? paddingRight : 8,
            marginTop: (marginTop || marginTop === 0) ? marginTop : 18,
            marginBottom: (marginBottom || marginBottom === 0) ? marginBottom : 18,
            borderRadius: 8,
            display: 'flex',
            width: width ? width : '100%',
        }, style]}
        onPress={onPress}
    >
        <Text style={[styles.orangeBackgroundBtnText, textStyle]}>{title}</Text>
    </TouchableOpacity>
)

export default ButtonPrimary

const styles = StyleSheet.create({
    orangeBackgroundBtn: {
        backgroundColor: '#F27121',
        color: '#F2F6F7',
        letterSpacing: 1.5,
        fontFamily: 'Pragmatica-Bold',
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'center',
        paddingTop: 19,
        paddingBottom: 17,
        paddingHorizontal: 8,
        // marginVertical: 18,
        borderRadius: 8,
        display: 'flex',
        width: '100%',
    },
    orangeBackgroundBtnText: {
        color: '#F2F6F7',
        letterSpacing: 1.5,
        fontFamily: 'Pragmatica-Bold',
        fontSize: 14,
        lineHeight: 20,
        textAlign: 'center',
    },
})