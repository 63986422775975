import {
    ADD_DELIVERY_ADDRESS, ADD_DELIVERY_ADDRESS_FAIL, ADD_DELIVERY_ADDRESS_SUCCESS,
    GET_DELIVERY_ADDRESSES, GET_DELIVERY_ADDRESSES_FAIL, GET_DELIVERY_ADDRESSES_SUCCESS,
    SET_ADDRESS_DEFAULT, DELETE_ADDRESS,
    CLEAR_ERRORS,
    SET_MAP_LATLNG
} from "./delivery.types"
import axios from 'axios'

export const getDeliveryAddresses = () => {
    return (dispatch) => {
        dispatch(deliveryAddresses())
        return (
            axios.get(`/delivery/addresses`)
                .then(({ data }) => {
                    return dispatch(deliveryAddressesSuccess(data))
                })
                .catch(err => dispatch(deliveryAddressesFail()))
        )
    }
}

export const setDefault = (address_id) => {
    return (dispatch) => {
        return (
            axios.post(`/delivery/addresses/set-default`, { address_id })
                .then(res => {
                    dispatch(getDeliveryAddresses())
                    return dispatch(setAsDefault())
                })
                .catch(err => console.log(err))
        )
    }
}

export const deleteAddress = (address_id) => {
    return (dispatch) => {
        return (
            axios.delete(`/delivery/addresses/${address_id}`)
                .then(res => {
                    console.log(res);
                    dispatch(getDeliveryAddresses())
                    return dispatch({ type: DELETE_ADDRESS })
                })
                .catch(err => console.log(err))
        )
    }
}

export const addDeliveryAddress = (address, closeModal) => {
    return (dispatch) => {
        dispatch(newDeliveryAddress())
        return (
            axios.post(`/delivery/addresses`, { ...address })
                .then(({ data }) => {
                    closeModal()
                    if (address.is_default) {
                        dispatch(setDefault(data.payload.id))
                    }
                    return dispatch(newDeliveryAddressSuccess(data.payload))
                })
                .catch(err => dispatch((newDeliveryAddressFail(err.response.data))))
        )
    }
}

export const getLatLng = (address) => {
    const { address_line_1 = '', city = '', county = '', postal_code = '' } = address
    const instance = axios.create()
    delete instance.defaults.headers.common['Authorization'];
    console.log("INSIDE LAT LNG", address)
    return (dispatch) => {
        return (
            instance.get(`https://maps.googleapis.com/maps/api/geocode/json?`, {
                params: {
                    address: `${address_line_1 && address_line_1} ${city && city} ${county && county} ${postal_code && postal_code}`,
                    key: 'AIzaSyDUExEkD_oePBF31-Lhw1tIRZjWHlIpfNw'
                }
            })
                .then(({ data }) => {
                    console.log("INSIDE THEN")
                    const countryCode = {
                        country_code: data.results[0].address_components.filter(items => {
                            if (items.types.includes('country')) return items
                        })[0].short_name
                    }
                    dispatch(setMapLatLng({ ...countryCode, ...data.results[0].geometry.location }))
                    return { ...countryCode, ...data.results[0].geometry.location }
                })
                .catch(err => console.log(err))
        )
    }
}

export const clearError = () => {
    return {
        type: CLEAR_ERRORS
    }
}

const deliveryAddresses = () => {
    return {
        type: GET_DELIVERY_ADDRESSES
    }
}

const deliveryAddressesSuccess = (data) => {
    return {
        type: GET_DELIVERY_ADDRESSES_SUCCESS,
        data
    }
}

const deliveryAddressesFail = () => {
    return {
        type: GET_DELIVERY_ADDRESSES_FAIL
    }
}

const newDeliveryAddress = () => {
    return {
        type: ADD_DELIVERY_ADDRESS
    }
}

const newDeliveryAddressSuccess = (data) => {
    return {
        type: ADD_DELIVERY_ADDRESS_SUCCESS,
        data
    }
}

const newDeliveryAddressFail = (error) => {
    return {
        type: ADD_DELIVERY_ADDRESS_FAIL,
        error
    }
}

const setAsDefault = () => {
    return {
        type: SET_ADDRESS_DEFAULT
    }
}

const setMapLatLng = (data) => {
    return {
        type: SET_MAP_LATLNG,
        data
    }
}