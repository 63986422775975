import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, Platform, Modal, TouchableOpacity, ScrollView } from 'react-native';
import { connect } from "react-redux";

import i18n from 'i18n-js'

import ModalWeb from 'modal-react-native-web';
// import Modal from 'modal-enhanced-react-native-web';

import globalStyles from "../assets/globalStyles";
import { CloseModal } from './svg/SnapioImages'

import ButtonPrimary from "./buttonPrimary";
import Counter from "./counter";
import OptionList from "./optionList";
import AddonList from './addonList';

import { addItem, increaseQuantity, decreaseQuantity } from "../redux/cart/cart.actions";

const QuickBuyModal = ({ visible, onPress, data, addItem, singleProduct }) => {
    const [count, setCount] = useState(1)
    return (
        <View>
            {
                Platform.OS === 'web' ?
                    <ModalWeb
                        visible={visible}
                        transparent={true}
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(22,22,22,0.8)'
                        }}
                        ariaHideApp={false}
                    >
                        <View style={{
                            flex: 1,
                            backgroundColor: 'rgba(22,22,22,0.8)'
                        }}>
                            <View style={{
                                position: 'absolute',
                                width: 'auto',
                                maxWidth: '100%',
                                backgroundColor: 'white',
                                right: 20,
                                left: 20,
                                bottom: 20,
                                maxHeight: '90%',
                                borderRadius: 16,
                                paddingHorizontal: 16,
                                paddingTop: 28,
                                paddingBottom: 20,
                                alignItems: 'center',
                            }}>
                                <ScrollView style={{ width: '100%', height: '80%' }}>
                                    <TouchableOpacity
                                        style={{ position: 'absolute', right: 0, top: 0 }}
                                        onPress={onPress}
                                    >
                                        <CloseModal />
                                    </TouchableOpacity>
                                    <Text style={[globalStyles.h3, { minWidth: '100%', textAlign: 'center', marginBottom: 1 }]}>{i18n.t('quick_modal.general')}</Text>
                                    <Text style={globalStyles.p}>{data.description}</Text>
                                    <View style={{ width: '100%', flex: 2, zIndex: 10 }}>
                                        <AddonList />
                                    </View>
                                    {/* <OptionList productItems={shopOptions} setData={setData} title='Choose volume' /> */}
                                    <Counter count={count} setCount={setCount} />
                                    <ButtonPrimary title={i18n.t('quick_modal.add_to_cart')} onPress={() => {
                                        addItem(singleProduct.id, count);
                                        onPress();
                                    }} />
                                </ScrollView>
                            </View>
                        </View>
                    </ModalWeb>
                    :
                    <Modal
                        visible={visible}
                        transparent={true}
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: 'rgba(22,22,22,0.8)'
                        }}
                    >
                        <View style={{
                            flex: 1,
                            backgroundColor: 'rgba(22,22,22,0.8)'
                        }}>
                            <View style={{
                                position: 'absolute',
                                width: 'auto',
                                maxWidth: '100%',
                                backgroundColor: 'white',
                                right: 20,
                                left: 20,
                                bottom: 20,
                                height: 'auto',
                                borderRadius: 16,
                                paddingHorizontal: 16,
                                paddingTop: 28,
                                paddingBottom: 20,
                                alignItems: 'center',
                            }}>
                                <Text onPress={onPress}>Close</Text>
                                <Text style={[globalStyles.h3, { minWidth: '100%', textAlign: 'center', marginBottom: 1 }]}>{i18n.t('quick_modal.general')}</Text>
                                <Text style={globalStyles.p}>{data.description}</Text>
                                {/* <OptionList productItems={shopOptions} setData={setData} title='Choose volume' /> */}
                                <Counter count={count} setCount={setCount} />
                                <ButtonPrimary title={i18n.t('quick_modal.add_to_cart')} onPress={() => {
                                    shopOptions.map(item => item.selected && addItem(item, count, data.image, data.description))
                                }} />
                            </View>
                        </View>
                    </Modal>
            }
        </View>
    )

}

const mapDispatchToProps = (dispatch) => {
    return ({
        addItem: (item, count) => dispatch(addItem(item, count))
    })
};

const mapStateToProps = (state) => ({
    singleProduct: state.product.singleProduct
})

export default connect(mapStateToProps, mapDispatchToProps)(QuickBuyModal)

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        width: '100%',
        backgroundColor: 'white',
    },
    headerText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 16,
        lineHeight: 22,
        color: '#161616',
    },
    close: {
        position: 'absolute',
        right: 20,
        alignSelf: 'center'
    }
})