import React, { useState } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Modal, Platform, Image } from 'react-native';
import ModalWeb from 'modal-react-native-web';

import { CloseModal, SendBox, MasterCard } from "./svg/SnapioImages";

import ButtonPrimary from './buttonPrimary'

import globalStyles, { colors } from "../assets/globalStyles";
import { ScrollView } from "react-native-gesture-handler";

const OrderDetails = ({ modalTitle, content, visible, onPress, navigation, }) => {
    const [testData, setTestData] = useState([
        {
            id: '16219026-a15f-4304-b05d-b118c03db183',
            image: 'https://telegraf.bb.lv/media/k2/items/cache/90eec687f2034ef6d9174604687885b8_L.jpg',
            price: '34.60',
            date: '24.06.2020',
            status: 'ORDERED',
            description: 'Radiant Energy serums sejai, 30ml and',
            items: 6
        },
        {
            id: '16219026-a15f-430-b05d-b118c03db183',
            image: 'https://telegraf.bb.lv/media/k2/items/cache/90eec687f2034ef6d9174604687885b8_L.jpg',
            price: '34.60',
            date: '24.06.2020',
            status: 'ORDERED',
            description: 'Radiant Energy serums sejai, 30ml and',
            items: 6
        },
        {
            id: '16219026-a15f-40-b05d-b118c03db183',
            image: 'https://telegraf.bb.lv/media/k2/items/cache/90eec687f2034ef6d9174604687885b8_L.jpg',
            price: '34.60',
            date: '24.06.2020',
            status: 'ORDERED',
            description: 'Radiant Energy serums sejai, 30ml and',
            items: 6
        },
    ])

    return (
        <View>
            {
                Platform.OS === 'web' ?
                    <ModalWeb
                        visible={visible}
                        ariaHideApp={false}
                    >
                        <View style={styles.headerContainer}>
                            <Text style={styles.headerText}>{modalTitle}</Text>
                            <TouchableOpacity style={styles.close} onPress={onPress}><CloseModal /></TouchableOpacity>
                        </View>
                        <ScrollView style={styles.container}>
                            <View style={[{ paddingVertical: 24, borderBottomWidth: 1, borderColor: colors.seperator }]}>
                                <Text style={[globalStyles.h3, { marginBottom: 12 }]}>It’s delivered!</Text>
                                <Text style={[globalStyles.p, { marginTop: 0, marginBottom: 0 }]}>Your order has been delivered. We hope you love it!</Text>
                            </View>
                            <View style={{ paddingTop: 17, paddingBottom: 33, flexDirection: 'row' }}>
                                <SendBox style={{ marginRight: 16 }} />
                                <View style={{ flex: 2 }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Order No:</Text>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>3248239482</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Merchant</Text>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Madara Cosmetics</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Order date</Text>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>5 June 2020</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Shipping date</Text>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>6 June 2020</Text>
                                    </View>
                                </View>
                            </View>
                            <View>
                                <Text style={[globalStyles.h3]}>Items</Text>
                                {
                                    testData.map((item, index) => {
                                        return (
                                            <View
                                                style={[{
                                                    flexDirection: 'row',
                                                    paddingTop: 16,
                                                    paddingBottom: 13,
                                                    borderBottomWidth: 1,
                                                    borderColor: colors.seperator,
                                                    width: 'auto',
                                                }, testData.length - 1 === index && { borderBottomWidth: 0 }]}
                                                key={item.id}>
                                                <Image
                                                    style={{ height: 96, width: '25%', resizeMode: 'contain' }}
                                                    source={{ uri: item.image }}
                                                />
                                                <View style={{ width: '75%', paddingLeft: 18 }}>
                                                    <Text style={[globalStyles.p, { marginBottom: 8 }]}>Radiant Energy serums sejai, 30ml </Text>
                                                    <Text style={[globalStyles.h4, { marginBottom: 0 }]}>34,60 €</Text>
                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                            <View>
                                <Text style={[globalStyles.h3, { marginBottom: 18 }]}>Delivered to</Text>
                                <View style={[{ borderTopWidth: 1, borderTopColor: colors.seperator, paddingTop: 18 }]}>
                                    <Text style={[globalStyles.p, { marginBottom: 8 }]}>Zane Helberga</Text>
                                    <Text style={[globalStyles.p]}>Pakomāts DPD, Aristīda Briāna</Text>
                                </View>
                            </View>
                            <View>
                                <Text style={[globalStyles.h3, { marginBottom: 12 }]}>Payment</Text>
                                <View style={[{
                                    borderTopWidth: 1,
                                    borderTopColor: colors.seperator,
                                    paddingTop: 16,
                                    flexDirection: 'row',
                                    alignItems: 'flex-start'
                                }]}>
                                    <MasterCard style={{ marginRight: 16 }} />
                                    <View>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Mastercard Gold</Text>
                                        <Text style={[globalStyles.tag]}>(9988) Exp. 02/23 ZANE HELBERGA </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ marginTop: 38 }}>
                                <Text style={[globalStyles.h3, { marginBottom: 20 }]}>Order total</Text>
                                <View style={{
                                    borderTopWidth: 1,
                                    borderTopColor: colors.seperator,
                                    paddingTop: 19,
                                }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', marginVertical: 7 }}>
                                        <Text style={[globalStyles.tag, { marginTop: 0, marginBottom: 0 }]}>Subtotal</Text>
                                        <Text style={[globalStyles.p, { marginTop: 0, marginBottom: 0 }]}>€ 57.03</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', marginVertical: 7 }}>
                                        <Text style={[globalStyles.tag, { marginTop: 0, marginBottom: 0 }]}>Delivery</Text>
                                        <Text style={[globalStyles.p, { marginTop: 0, marginBottom: 0 }]}>€ 2,99</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', marginVertical: 7 }}>
                                        <Text style={[globalStyles.p, { marginTop: 0, marginBottom: 0 }]}>Total to pay</Text>
                                        <Text style={[globalStyles.h4, { marginTop: 0, marginBottom: 0 }]}>€ 60,03</Text>
                                    </View>
                                    <ButtonPrimary title="ORDER AGAIN" onPress={() => navigation.navigate('MyStack', { screen: 'Order Complete' })} />
                                </View>
                            </View>
                        </ScrollView>
                    </ModalWeb>
                    :
                    <Modal
                        visible={visible}
                    >
                        <View style={styles.headerContainer}>
                            <Text style={styles.headerText}>{modalTitle}</Text>
                            <TouchableOpacity style={styles.close} onPress={onPress}><CloseModal /></TouchableOpacity>
                        </View>
                        <ScrollView style={styles.container}>
                            <View style={[{ paddingVertical: 24, borderBottomWidth: 1, borderColor: colors.seperator }]}>
                                <Text style={[globalStyles.h3, { marginBottom: 12 }]}>It’s delivered!</Text>
                                <Text style={[globalStyles.p, { marginTop: 0, marginBottom: 0 }]}>Your order has been delivered. We hope you love it!</Text>
                            </View>
                            <View style={{ paddingTop: 17, paddingBottom: 33, flexDirection: 'row' }}>
                                <SendBox style={{ marginRight: 16 }} />
                                <View style={{ flex: 2 }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Order No:</Text>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>3248239482</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Merchant</Text>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Madara Cosmetics</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Order date</Text>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>5 June 2020</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 14 }}>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Shipping date</Text>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>6 June 2020</Text>
                                    </View>
                                </View>
                            </View>
                            <View>
                                <Text style={[globalStyles.h3]}>Items</Text>
                                {
                                    testData.map((item, index) => {
                                        return (
                                            <View
                                                style={[{
                                                    flexDirection: 'row',
                                                    paddingTop: 16,
                                                    paddingBottom: 13,
                                                    borderBottomWidth: 1,
                                                    borderColor: colors.seperator,
                                                    width: 'auto',
                                                }, testData.length - 1 === index && { borderBottomWidth: 0 }]}
                                                key={item.id}>
                                                <Image
                                                    style={{ height: 96, width: '25%', resizeMode: 'contain' }}
                                                    source={{ uri: item.image }}
                                                />
                                                <View style={{ width: '75%', paddingLeft: 18 }}>
                                                    <Text style={[globalStyles.p, { marginBottom: 8 }]}>Radiant Energy serums sejai, 30ml </Text>
                                                    <Text style={[globalStyles.h4, { marginBottom: 0 }]}>34,60 €</Text>
                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                            <View>
                                <Text style={[globalStyles.h3, { marginBottom: 18 }]}>Delivered to</Text>
                                <View style={[{ borderTopWidth: 1, borderTopColor: colors.seperator, paddingTop: 18 }]}>
                                    <Text style={[globalStyles.p, { marginBottom: 8 }]}>Zane Helberga</Text>
                                    <Text style={[globalStyles.p]}>Pakomāts DPD, Aristīda Briāna</Text>
                                </View>
                            </View>
                            <View>
                                <Text style={[globalStyles.h3, { marginBottom: 12 }]}>Payment</Text>
                                <View style={[{
                                    borderTopWidth: 1,
                                    borderTopColor: colors.seperator,
                                    paddingTop: 16,
                                    flexDirection: 'row',
                                    alignItems: 'flex-start'
                                }]}>
                                    <MasterCard style={{ marginRight: 16 }} />
                                    <View>
                                        <Text style={[globalStyles.p, { marginBottom: 0, marginTop: 0 }]}>Mastercard Gold</Text>
                                        <Text style={[globalStyles.tag]}>(9988) Exp. 02/23 ZANE HELBERGA </Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ marginTop: 38 }}>
                                <Text style={[globalStyles.h3, { marginBottom: 20 }]}>Order total</Text>
                                <View style={{
                                    borderTopWidth: 1,
                                    borderTopColor: colors.seperator,
                                    paddingTop: 19,
                                }}>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', marginVertical: 7 }}>
                                        <Text style={[globalStyles.tag, { marginTop: 0, marginBottom: 0 }]}>Subtotal</Text>
                                        <Text style={[globalStyles.p, { marginTop: 0, marginBottom: 0 }]}>€ 57.03</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', marginVertical: 7 }}>
                                        <Text style={[globalStyles.tag, { marginTop: 0, marginBottom: 0 }]}>Delivery</Text>
                                        <Text style={[globalStyles.p, { marginTop: 0, marginBottom: 0 }]}>€ 2,99</Text>
                                    </View>
                                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline', marginVertical: 7 }}>
                                        <Text style={[globalStyles.p, { marginTop: 0, marginBottom: 0 }]}>Total to pay</Text>
                                        <Text style={[globalStyles.h4, { marginTop: 0, marginBottom: 0 }]}>€ 60,03</Text>
                                    </View>
                                    <ButtonPrimary title="ORDER AGAIN" onPress={() => navigation.navigate('MyStack', { screen: 'Order Complete' })} />
                                </View>
                            </View>
                        </ScrollView>
                    </Modal>
            }
        </View>
    )
}

export default OrderDetails

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        width: '100%',
        backgroundColor: 'white',
    },
    headerContainer: {
        paddingTop: 16,
        paddingBottom: 17,
        borderBottomWidth: 1,
        borderColor: 'rgba(22,22,22,0.2)',
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: 20,
        textAlign: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 16,
        lineHeight: 22,
        color: '#161616',
    },
    close: {
        position: 'absolute',
        right: 20,
        alignSelf: 'center'
    }
})