import { combineReducers } from 'redux';

import cartReducer from './cart/cart.reducer';
import userReducer from './user/user.reducer';
import paymentReducer from './payment/payment.reducer';
import countriesReducer from './api/api.reducer';
import loginReducer from './login/login.reducer';
import countryCodeReducer from './input/input.reducer';
import productReducer from './product/product.reducer';
import deliveryReducer from './delivery/delivery.reducer';
import orderReducer from './order/order.reducer'

export default combineReducers({
    cart: cartReducer,
    user: userReducer,
    paymentMethods: paymentReducer,
    api: countriesReducer,
    login: loginReducer,
    selectCountry: countryCodeReducer,
    product: productReducer,
    deliveryAddress: deliveryReducer,
    order: orderReducer,
});