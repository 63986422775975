import React from "react";
import { View, Text } from 'react-native';
import { Error } from "../components/svg/SnapioImages";

import globalStyles from "../assets/globalStyles";

const ErrorField = ({ message }) => (
    <View style={{ flexDirection: 'row', marginTop: -6, marginBottom: 8 }}>
        <Error style={{ marginRight: 3, minWidth: 17, minHeight: 16 }} /><Text style={[globalStyles.inputError,]}>{message}</Text>
    </View>
)

export default ErrorField