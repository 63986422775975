import React, { useState } from 'react'
import { View, Text, ScrollView, Image, TouchableOpacity } from 'react-native'

import NewSnapFooter from '../components/newSnapFooter'
import ButtonPrimary from '../components/buttonPrimary'
import ButtonSecondary from '../components/buttonSecondary'
import OrderDetails from '../components/orderDetails'

import globalStyles, { colors } from '../assets/globalStyles';
import { BackButton } from '../components/svg/SnapioImages'

const OrderHistory = ({ navigation }) => {
    const [testData, setTestData] = useState([
        {
            id: '16219026-a15f-4304-b05d-b118c03db183',
            image: 'https://telegraf.bb.lv/media/k2/items/cache/90eec687f2034ef6d9174604687885b8_L.jpg',
            price: '34.60',
            date: '24.06.2020',
            status: 'ORDERED',
            description: 'Radiant Energy serums sejai, 30ml and',
            items: 6
        }
    ])

    const [productModal, setProductModal] = useState(false);

    return (
        <View style={{ flex: 1 }}>
            <View style={globalStyles.headerContainer}>
                <TouchableOpacity style={globalStyles.headerBackButton} onPress={() => navigation.goBack()}><BackButton /></TouchableOpacity>
                <Text style={globalStyles.headerText}>My orders</Text>
            </View>
            <ScrollView contentContainerStyle={{
                paddingHorizontal: 20,
                paddingVertical: 16,
                flex: 2
            }}>
                {testData.map(item =>
                    <View
                        style={{
                            flexDirection: 'row',
                            paddingVertical: 24,
                            borderBottomWidth: 1,
                            borderColor: colors.seperator,
                            width: 'auto',
                        }}
                        key={item.id}>
                        <Image
                            style={{ height: 128, width: '25%', resizeMode: 'contain' }}
                            source={{ uri: item.image }}
                        />
                        <View style={{ width: '75%', paddingLeft: 18 }}>
                            <View style={{ flexDirection: "row", justifyContent: 'space-between', alignItems: 'baseline', marginBottom: 6 }}>
                                <Text style={[globalStyles.h4, { marginBottom: 0 }]}>34,60 €</Text>
                                <Text style={[globalStyles.tag, { marginBottom: 0 }]}>24.06.2020 Ordered</Text>
                            </View>
                            <Text style={[globalStyles.p, { marginBottom: 8 }]}>Radiant Energy serums sejai, 30ml and +5 more items</Text>
                            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                <ButtonPrimary
                                    style={{ width: '48%', paddingTop: 10, paddingBottom: 8, marginBottom: 0, marginTop: 0 }}
                                    textStyle={{ fontSize: 12 }} title="VIEW ORDER"

                                    onPress={() => { setProductModal(!productModal) }} />
                                <ButtonSecondary style={{ width: '48%', paddingTop: 10, paddingBottom: 8, marginBottom: 0, marginTop: 0 }} textStyle={{ fontSize: 12 }} title="ORDER AGAIN" />
                            </View>
                        </View>
                    </View>
                )}
            </ScrollView>
            <OrderDetails
                onPress={() => { setProductModal(!productModal) }}
                modalTitle='Order details'
                visible={productModal}
            />
            <NewSnapFooter />
        </View >
    )
}

export default OrderHistory;