import React, { useState, useEffect } from 'react';
import { View, Image, Text, StyleSheet, TouchableOpacity } from 'react-native';

import i18n from 'i18n-js'

import ButtonPrimary from "./buttonPrimary";
import QuickBuyModal from "./quickBuyModal";

import globalStyles from "../assets/globalStyles";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSingleProduct } from '../redux/product/product.actions'

const ProductList = ({ navigation, productList, getSingleProduct }) => {

    const [modalVisible, setModalVisible] = useState(false);
    const [itemData, setItemData] = useState([]);

    const openModal = (product) => {
        setItemData(product);
        setModalVisible(!modalVisible);
    }

    useEffect(() => {
    }, [])

    return (
        <View style={{ width: '100%' }}>
            {
                productList.groups && productList.groups.map((item, index) => (
                    <View style={{ width: '100%' }} key={item.id}>
                        { item.title && <Text style={[globalStyles.h3, { marginTop: 24 }]}>{item.title}</Text>}
                        { item.description && <Text style={[globalStyles.p]}>{item.description}</Text>}
                        {
                            item.products.map((product, index) => (
                                <View
                                    style={{
                                        flexDirection: 'row',
                                        paddingVertical: 24,
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(22,22,22,0.1)"
                                    }}
                                    key={product.id}
                                >
                                    <TouchableOpacity style={{ height: 128, width: '25%' }}
                                        onPress={() => {
                                            getSingleProduct(product.id)
                                            navigation.navigate('MyStack', { screen: i18n.t("menu.product") })
                                        }}
                                    >
                                        <Image
                                            style={{ height: '100%', width: '100%', resizeMode: 'contain' }}
                                            source={{ uri: product.images[0] }}
                                        />
                                    </TouchableOpacity>

                                    <View style={{ flex: 2, marginLeft: 16 }}>
                                        <Text style={[globalStyles.h4, { marginBottom: 4 }]}>{product.price} {product.currency_symbol}</Text>
                                        <Text style={{ fontFamily: 'Pragmatica-Book', fontSize: 14, lineHeight: 20, color: '#161616' }}>{product.name}</Text>
                                        <ButtonPrimary
                                            title="BUY NOW"
                                            width={100}
                                            paddingTop={10}
                                            paddingBottom={9}
                                            marginBottom={0}
                                            marginTop='auto'
                                            onPress={() => {
                                                getSingleProduct(product.id)
                                                openModal(product);
                                            }}
                                        />
                                    </View>
                                </View>
                            ))
                        }
                    </View>
                ))
            }
            < QuickBuyModal key={itemData.id} visible={modalVisible} onPress={() => { setModalVisible(!modalVisible) }} data={itemData} />
        </View>
    )
}

const mapStateToProps = (state) => ({
    productList: state.product.productList,
})

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({ getSingleProduct }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductList)