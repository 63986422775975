import React, { useState, useEffect } from 'react';
import { View, Text, Linking } from 'react-native';
import {
  createDrawerNavigator,
  DrawerContentScrollView,
  DrawerItemList,
  DrawerItem,
} from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useFonts } from 'expo-font';
import { AppLoading } from 'expo';
import { Provider, connect, bindActionCreators } from 'react-redux';
import { getUserData } from './redux/user/user.actions'
import { logout } from './redux/login/login.action'

import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import translations from './translations/translations'
// import stripe from 'tipsi-stripe'
import axios from 'axios'

import ProductPage from "./pages/product";
import ListProduct from "./pages/listProduct";
import CartViewStack from "./pages/cartViewStack";
import FullScreenModal from "./components/fullScreenModal";
import ProfileInformation from "./pages/profileInformation";
import PaymentMethodsPage from "./pages/paymentMethods";
import MyAddressesPage from './pages/myAddresses';
import OrderComplete from './pages/orderComplete';
import OrderHistory from './pages/orderHistory';
import LoginPage from './pages/login/login';
import LoginSmsPage from './pages/login/loginSms';
import LoginWelcome from './pages/login/loginWelcome';
import UnderlineExample from './pages/login/underlineExample';
import TestPage from './pages/testPage';

import store from "./redux/store";

import globalStyles from "./assets/globalStyles";

i18n.translations = {
  en: translations.en,
  ru: translations.ru
};

// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;

const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();
const Login = createStackNavigator();

// stripe.setOptions({
//   publishableKey: 'pk_test_51IDXQ5E1oSxo59lx2RN2cGT92zmFCb6I8GVKjon4MunAe4mxtYkdvaKV8Fo4iyJOYp4vUtQkZdkD35XcjOOZ1pUb0055Qt0MJM',
// })

// stripe.setStripeAccount(null)


const MyStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false, animationEnabled: true }}
      // initialRouteName="Product"
      initialRouteName="List Product"
    >
      <Stack.Screen name={i18n.t("menu.product")} component={ProductPage} />
      <Stack.Screen name="List Product" component={ListProduct} />
      <Stack.Screen name="CartView" component={CartViewStack} />
      <Stack.Screen name="Order Complete" component={OrderComplete} />
    </Stack.Navigator>
  );
}

const LoginStack = () => {
  return (
    <Login.Navigator
      screenOptions={{ headerShown: false, animationEnabled: true }}
      // initialRouteName="Product"
      initialRouteName="Login"
    // initialRouteName="UnderlineExample"
    >
      <Login.Screen name="Login" component={LoginPage} />
      {/* <Login.Screen name="UnderlineExample" component={UnderlineExample} /> */}
      <Login.Screen name="Login SMS" component={LoginSmsPage} />
      <Login.Screen name="Login Welcome" component={LoginWelcome} />
    </Login.Navigator>
  );
}

const CustomDrawerContent = ({ logout, user, ...props }) => {
  return (
    <DrawerContentScrollView
      {...props}
      style={{
        marginBottom: 30
      }}
      contentContainerStyle={{
        height: '100%'
      }}

    >
      <View style={{ marginBottom: 40 }}>
        <Text style={[globalStyles.h2, { marginBottom: 8 }]}>{user.first_name}</Text>
        <Text style={[globalStyles.p, { marginTop: 0, marginBottom: 0 }]}>{user.phone_number}</Text>
      </View>
      <DrawerItemList {...props} />
      <DrawerItem
        label={i18n.t("menu.log_out")}
        style={{
          marginTop: 'auto',
          height: 40,
        }}
        labelStyle={[
          globalStyles.p,
          {
            marginBottom: 0,
            marginLeft: -8,
            marginRight: 0,
            marginTop: 'auto',
            color: '#161616',
          }
        ]}
        onPress={() => {
          logout(props.navigation);
        }}
      />
    </DrawerContentScrollView>
  );
}

const MyDrawer = ({ getUserData }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const isLogged = localStorage.getItem('user-token');
  axios.defaults.baseURL = 'https://erp.test.snapio.shop/api';

  if (isLogged) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + isLogged;
    getUserData();
  }

  return (
    <Drawer.Navigator
      initialRouteName={isLogged ? "MyStack" : "LoginStack"}
      drawerContent={(props) => {
        const filteredProps = {
          ...props,
          state: {
            ...props.state,
            routeNames: props.state.routeNames.filter(
              (routeName) => {
                routeName !== 'LoginStack' && routeName !== 'MyStack';
              },
            ),
            routes: props.state.routes.filter(
              (route) =>
                route.name !== 'LoginStack' && route.name !== 'MyStack'
            ),
          },
        };
        return (
          <CustomDrawerContentConnected {...filteredProps} />
        );
      }}
      drawerStyle={{
        width: '82%',
        paddingTop: 40,
        paddingHorizontal: 20,
      }}
      drawerContentOptions={{
        activeTintColor: 'red',
        itemStyle: {
          marginHorizontal: 0,
          backgroundColor: 'white',
          color: 'red',
          marginBottom: 0,
          marginLeft: 0,
          marginRight: 0,
          marginTop: 0,
          height: 55,
          marginVertical: 0,
          justifyContent: 'center',
          borderTopColor: 'rgba(22,22,22,0.1)',
          borderTopWidth: 1,
        },
        labelStyle: [
          globalStyles.p,
          {
            marginBottom: 0,
            marginLeft: -8,
            marginRight: 0,
            marginTop: 0,
            paddingTop: 15,
            paddingBottom: 16,
          }
        ],
      }}
    >
      {/* <Drawer.Screen name={i18n.t("menu.test_page")} component={TestPage} /> */}
      <Drawer.Screen name={i18n.t("menu.profile_information")} component={ProfileInformation} />
      <Drawer.Screen name={i18n.t("menu.payment")} component={PaymentMethodsPage} />
      <Drawer.Screen name="LoginStack" component={LoginStack} />
      <Drawer.Screen name={i18n.t("menu.order_history")} component={OrderHistory} />
      <Drawer.Screen name="MyStack" component={MyStack} />
      <Drawer.Screen name={i18n.t("menu.my_addresses")} component={MyAddressesPage} />
      <Drawer.Screen name={i18n.t("menu.legal")}>
        {props => <FullScreenModal {...props} visible={modalVisible} modalTitle='Terms of use' onPress={() => { setModalVisible(!modalVisible) }} />}
      </Drawer.Screen>
    </Drawer.Navigator>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.userData
})

const mapDispatchToProps = (dispatch) => ({
  getUserData: () => dispatch(getUserData()),
  logout: (navigation) => dispatch(logout(navigation))
})

const MyDrawerConnected = connect(null, mapDispatchToProps)(MyDrawer)
const CustomDrawerContentConnected = connect(mapStateToProps, mapDispatchToProps)(CustomDrawerContent)

const App = () => {
  let [fontsLoaded] = useFonts({
    'Pragmatica-Bold': require('./assets/fonts/Pragmatica-Bold.otf'),
    'Pragmatica-Book': require('./assets/fonts/Pragmatica-Book.otf'),
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <Provider store={store}>
      <NavigationContainer
      // linking={{
      //   enabled: true
      // }}
      >
        {/* <MyDrawer /> */}
        <MyDrawerConnected />
      </NavigationContainer>
    </Provider>
  );
}

export default App