export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

export const CALCULATE_DELIVERY_PRICE = 'CALCULATE_DELIVERY_PRICE';
export const CALCULATE_DELIVERY_PRICE_SUCCESS = 'CALCULATE_DELIVERY_PRICE_SUCCESS';
export const CALCULATE_DELIVERY_PRICE_FAIL = 'CALCULATE_DELIVERY_PRICE_FAIL';

export const GET_DELIVERY_METHODS = 'GET_DELIVERY_METHODS';
export const GET_DELIVERY_METHODS_SUCCESS = 'GET_DELIVERY_METHODS_SUCCESS';
export const GET_DELIVERY_METHODS_FAIL = 'GET_DELIVERY_METHODS_FAIL';

export const GET_PICKUP_POINTS = 'GET_PICKUP_POINTS';
export const GET_PICKUP_POINTS_SUCCESS = 'GET_PICKUP_POINTS_SUCCESS';
export const GET_PICKUP_POINTS_FAIL = 'GET_PICKUP_POINTS_FAIL';

export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_FAIL = 'GET_PAYMENT_METHODS_FAIL';

export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const SELECT_PAYMENT_METHOD_SUCCESS = 'SELECT_PAYMENT_METHOD_SUCCESS';
export const SELECT_PAYMENT_METHOD_FAIL = 'SELECT_PAYMENT_METHOD_FAIL';

export const SELECT_DELIVERY_METHOD = 'SELECT_DELIVERY_METHOD';
export const SELECT_DELIVERY_METHOD_SUCCESS = 'SELECT_DELIVERY_METHOD_SUCCESS';
export const SELECT_DELIVERY_METHOD_FAIL = 'SELECT_DELIVERY_METHOD_FAIL';

export const SELECT_PICKUP_POINT = 'SELECT_PICKUP_POINT';
export const SELECT_PICKUP_POINT_SUCCESS = 'SELECT_PICKUP_POINT_SUCCESS';
export const SELECT_PICKUP_POINT_FAIL = 'SELECT_PICKUP_POINT_FAIL';

export const SELECT_DELIVERY_ADDRESS = 'SELECT_DELIVERY_ADDRESS';
export const SELECT_DELIVERY_ADDRESS_SUCCESS = 'SELECT_DELIVERY_ADDRESS_SUCCESS';
export const SELECT_DELIVERY_ADDRESS_FAIL = 'SELECT_DELIVERY_ADDRESS_FAIL';