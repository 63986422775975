import * as React from 'react';
import { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity, Text, Dimensions } from 'react-native';

import { connect } from 'react-redux';
import globalStyles from '../assets/globalStyles';
import { selectPaymentMethod } from '../redux/payment/payment.actions';
import { selectDeliveryMethod, selectPickupPoint, selectDeliveryAddress } from '../redux/order/order.actions'
import { getDeliveryAddresses } from '../redux/delivery/delivery.actions'

import DropDownPicker from 'react-native-dropdown-picker';

import AddressModal from './addressModal'

const OptionListDelivery = ({
    deliveryMethods, selectedDeliveryMethod, selectDeliveryMethod,
    pickupPoints, selectPickupPoint, getDeliveryAddresses,
    deliveryAddresses, selectDeliveryAddress, selectedDeliveryAddress }) => {

    const [addressModal, setAddressModal] = useState({
        toggle: false,
        edit: false
    });

    const toggleAddressModal = (editVal = false) => {
        setAddressModal({
            ...addressModal,
            toggle: !addressModal.toggle,
            edit: editVal,
        });
    }

    const [optionItems, setOptionItems] = useState([])
    const [deliveryAddress, setDeliveryAddress] = useState([])

    useEffect(() => {
        if (pickupPoints.length > 0) {
            setOptionItems(
                pickupPoints.map(item => {
                    item.label = item.title
                    return item;
                })
            )
        }
    }, [pickupPoints])

    useEffect(() => {
        if (selectedDeliveryMethod?.type === 'to_address') {
            getDeliveryAddresses();
        }
    }, [selectedDeliveryMethod])

    useEffect(() => {
        console.log('USE EFFECT BUG', deliveryAddresses);
        if (deliveryAddresses.length > 0) {
            setDeliveryAddress(
                deliveryAddresses.map(item => {
                    item.label = item.title_full_address
                    if (selectedDeliveryAddress.id === item.id) {
                        item.selected = true
                    } else {
                        item.selected = item.is_default
                    }

                    return item
                })
            )
        }
    }, [deliveryAddresses])

    return (
        <View style={{ width: '100%' }}>
            {
                deliveryMethods.map((item, index) => {
                    return (
                        <View key={item.id} style={index === 0 ? styles.optionContainerTopBotBorder : styles.optionContainer}>
                            <TouchableOpacity
                                style={styles.touchContainer}
                                onPress={() => selectDeliveryMethod(item.id)}
                            >
                                <View style={item.id === selectedDeliveryMethod.id ? styles.circleSelected : styles.circle}>
                                    {item.id === selectedDeliveryMethod.id ? <View style={styles.innerCircle}></View> : null}
                                </View>
                                <View style={{ flex: 1 }}>
                                    <Text style={styles.optionTitle}>{item.title}</Text>
                                    {
                                        item.id === selectedDeliveryMethod.id && item.type === 'to_address'
                                            ?
                                            <View>
                                                {deliveryAddress.length >= 1 ?
                                                    <View>
                                                        {
                                                            deliveryAddress.length === 1 ?
                                                                <View>
                                                                    <Text style={[styles.optionPrice, { marginTop: 0 }]}>{deliveryAddress[0]?.title_full_address}</Text>
                                                                    <Text
                                                                        style={globalStyles.link}
                                                                        onPress={() => {
                                                                            toggleAddressModal(true)
                                                                        }}
                                                                    >Manage address</Text>
                                                                </View>
                                                                :
                                                                <View>
                                                                    <DropDownPicker
                                                                        items={deliveryAddress}
                                                                        containerStyle={{ height: 40 }}
                                                                        style={{
                                                                            backgroundColor: '#EBEBEB',
                                                                            borderRadius: 8,
                                                                        }}
                                                                        containerStyle={{
                                                                            height: 48,
                                                                            marginBottom: 16
                                                                        }}
                                                                        itemStyle={{
                                                                            justifyContent: 'flex-start'
                                                                        }}
                                                                        dropDownStyle={{ backgroundColor: '#EBEBEB' }}
                                                                        onChangeItem={(item, index) => {
                                                                            selectDeliveryAddress(item);
                                                                        }}
                                                                    />
                                                                    <Text
                                                                        style={globalStyles.link}
                                                                        onPress={() => {
                                                                            toggleAddressModal(true)
                                                                        }}
                                                                    >Manage address</Text>
                                                                </View>

                                                        }
                                                    </View>
                                                    :
                                                    <Text
                                                        style={globalStyles.link}
                                                        onPress={() => {
                                                            toggleAddressModal(true)
                                                        }}
                                                    >Add address</Text>
                                                }
                                            </View>
                                            :
                                            null
                                    }
                                </View>
                            </TouchableOpacity>
                            <AddressModal
                                modalTitle='Add new payment method'
                                visible={addressModal.toggle}
                                edit={addressModal.edit}
                                onPress={() => {
                                    toggleAddressModal();
                                }} />
                        </View>
                    )
                })
            }
        </View >
    )
}

const mapStateToProps = (state) => ({
    // paymentMethods: state.paymentMethods.paymentMethods,
    deliveryMethods: state.order.deliveryMethods,
    deliveryAddresses: state.deliveryAddress.addresses,
    selectedDeliveryMethod: state.order.order.delivery_method,
    selectedDeliveryAddress: state.order.order.delivery_address,
    pickupPoints: state.order.pickupPoints
})

const mapDispatchToProps = (dispatch) => ({
    selectDeliveryMethod: (id) => dispatch(selectDeliveryMethod(id)),
    selectPickupPoint: (id) => dispatch(selectPickupPoint(id)),
    selectDeliveryAddress: (address) => dispatch(selectDeliveryAddress(address)),
    getDeliveryAddresses: () => dispatch(getDeliveryAddresses())
})

export default connect(mapStateToProps, mapDispatchToProps)(OptionListDelivery);

const styles = StyleSheet.create({
    optionContainer: {
        width: '100%',
        paddingTop: 21,
        paddingBottom: 18,
        borderBottomWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
    },
    optionContainerTopBotBorder: {
        width: '100%',
        paddingTop: 21,
        paddingBottom: 18,
        borderBottomWidth: 1,
        borderTopWidth: 0,
        borderColor: 'rgba(22, 22, 22, 0.1)',
    },
    touchContainer: {
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    optionHeader: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book',
        alignSelf: 'flex-start',
        marginBottom: 17,
        marginTop: 21,
    },
    circle: {
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        borderRadius: 100,
        borderWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
    },
    circleSelected: {
        width: 32,
        height: 32,
        minWidth: 32,
        minHeight: 32,
        borderRadius: 100,
        borderWidth: 1,
        backgroundColor: '#602C8B',
        borderColor: '#602C8B',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 16,
    },
    innerCircle: {
        width: 16,
        height: 16,
        minWidth: 16,
        minHeight: 16,
        backgroundColor: '#F6F6F6',
        borderRadius: 100,
    },
    optionTitle: {
        color: '#161616',
        fontSize: 16,
        lineHeight: 24,
        fontFamily: 'Pragmatica-Book',
        // marginBottom: 8
    },
    optionPrice: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book'
    }
})