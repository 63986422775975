import {
    SET_CURRENT_USER, EDIT_CURRENT_USER, GET_CURRENT_USER, GET_CURRENT_USER_FAIL, GET_CURRENT_USER_SUCCESS,
    UPDATE_CURRENT_USER_SUCCESS, UPDATE_CURRENT_USER, UPDATE_CURRENT_USER_FAIL
} from './user.types';

const INITIAL_STATE = {
    userData: {},
    isFetching: false,
    error: false,
    errorFields: {}
}

const userReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CURRENT_USER:
            return {
                ...state,
                userData: action.payload
            }
        case EDIT_CURRENT_USER:
            return {
                ...state,
                userData: { ...action.payload }
            }
        case GET_CURRENT_USER:
            return {
                ...state,
                isFetching: true
            }
        case GET_CURRENT_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                userData: action.data
            }
        case GET_CURRENT_USER_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case UPDATE_CURRENT_USER:
            return {
                ...state,
                isFetching: true,
                error: false,
            }
        case UPDATE_CURRENT_USER_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }
        case UPDATE_CURRENT_USER_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorFields: action.error
            }
        default:
            return state;
    }
}

export default userReducer;