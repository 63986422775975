import React from 'react';
import { StyleSheet, View, TouchableOpacity, StatusBar, Text } from 'react-native';
import { connect } from 'react-redux';
import { Logo, CartSvg, PersonSvg, DesktopLogoSnapio } from './svg/SnapioImages';
import globalStyles, { colors } from '../assets/globalStyles'

import '@expo/match-media'
import { useMediaQuery } from "react-responsive";



const SnapioHeader = ({ navigation, productsCount }) => {
    const isDesktop = useMediaQuery({
        minDeviceWidth: 1140
    })
    if (isDesktop) {
        return (
            <View style={{ width: '100%' }}>
                <View style={[styles.headerContainer, { alignItems: 'center' }]}>
                    <TouchableOpacity style={styles.headerButtons} onPress={() => navigation.toggleDrawer()}>
                        <PersonSvg />
                    </TouchableOpacity>
                    <DesktopLogoSnapio />
                    <TouchableOpacity onPress={() => navigation.navigate('MyStack', { screen: 'CartView' })}>
                        <View style={{ position: 'relative' }}>
                            <View style={{ position: 'relative' }}>
                                <CartSvg />
                            </View>
                        </View>
                    </TouchableOpacity>
                </View>
            </View>
        )
    } else {
        return (
            <View>
                <View style={styles.headerContainer}>
                    <TouchableOpacity style={styles.headerButtons} onPress={() => navigation.toggleDrawer()}>
                        <PersonSvg />
                    </TouchableOpacity>
                    <Logo />
                    <TouchableOpacity onPress={() => navigation.navigate('MyStack', { screen: 'CartView' })}>
                        <CartSvg />
                        {productsCount &&
                            <View style={{
                                position: 'absolute',
                                backgroundColor: colors.primary,
                                width: 16,
                                height: 16,
                                borderRadius: 50,
                                justifyContent: 'center',
                                alignItems: 'center',
                                top: -4,
                                right: -8
                            }}>
                                <Text style={{
                                    color: "#F6F6F6",
                                    fontFamily: "Pragmatica-Bold",
                                    fontSize: 11,
                                    lineHeight: 11,
                                }}>{productsCount}</Text>
                            </View>}
                    </TouchableOpacity>
                </View>
            </View>
        )
    }
}

const mapStateToProps = (state) => ({
    productsCount: state.cart.cartList.products_count,
})

export default connect(mapStateToProps)(SnapioHeader);

const styles = StyleSheet.create({
    headerContainer: {
        flex: 0,
        width: '100%',
        minHeight: 56,
        flexDirection: 'row',
        paddingHorizontal: 20,
        paddingVertical: 16,
        paddingTop: 16 + StatusBar.currentHeight,
        justifyContent: "space-between",
        borderBottomWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.1)',
        backgroundColor: 'white',
    },
    headerButtons: {
        width: 24,
        height: 24,
    }
})