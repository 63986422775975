export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const SELECT_METHOD = 'SELECT_METHOD';
export const SET_DEFAULT = 'SET_DEFAULT';

export const GET_CARDS = 'GET_CARDS';
export const GET_CARDS_SUCCESS = 'GET_CARDS_SUCCESS';
export const GET_CARDS_FAIL = 'GET_CARDS_FAIL';

export const REMOVE_CARD = 'REMOVE_CARD';
export const REMOVE_CARD_FAIL = 'REMOVE_CARD_FAIL';

export const GET_CARD = 'GET_CARD';
export const GET_CARD_SUCCESS = 'GET_CARD_SUCCESS';
export const GET_CARD_FAIL = 'GET_CARD_FAIL';

export const SET_CARD_AS_DEFAULT = 'SET_CARD_AS_DEFAULT';