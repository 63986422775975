import React, { useState } from 'react'
import { View, Text, StyleSheet } from 'react-native'

import FullScreenModal from '../fullScreenModal'

const DesktopFooter = () => {
    const [modalVisible, setModalVisible] = useState(false);

    return (
        <View style={styles.container}>
            <Text style={[styles.text, { fontSize: 16, lineHeight: 24 }]}>Powered by Snapio</Text>
            <View style={styles.textContainer}>
                <Text style={[styles.text, { color: 'rgba(22, 22, 22, 0.5)', marginRight: 30 }]} onPress={() => { setModalVisible(!modalVisible) }}>Terms & Conditions</Text>
                <Text style={[styles.text, { color: 'rgba(22, 22, 22, 0.5)' }]}>Privacy Policy</Text>
                <FullScreenModal visible={modalVisible} modalTitle='Terms of use' onPress={() => { setModalVisible(!modalVisible) }} />
            </View>
        </View>
    )
}

export default DesktopFooter

const styles = StyleSheet.create({
    container: {
        width: '100%',
        marginTop: 10,
        justifyContent: 'space-between',
        borderTopWidth: 1,
        borderColor: 'rgba(22, 22, 22, 0.2)',
        flexDirection: 'row',
    },
    text: {
        paddingTop: 14,
        paddingBottom: 16,
        color: '#161616',
        fontFamily: 'Pragmatica-Book',
        textAlign: 'center',
    },
    textContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    }
})