import React, { useState, useEffect } from 'react';
import { StyleSheet, Text, View, Image, Dimensions } from 'react-native';
import Swiper from 'react-native-web-swiper';

const window = Dimensions.get('window');
const screen = Dimensions.get('screen');

const ProductSlider = ({ swiperImages }) => {

    const [dimensions, setDimensions] = useState({ window, screen });

    const onChange = ({ window, screen }) => {
        setDimensions({ window, screen });
    };

    useEffect(() => {
        Dimensions.addEventListener('change', onChange);
        return () => {
            Dimensions.removeEventListener('change', onChange);
        };
    });

    const styles = StyleSheet.create({
        container: {
            position: 'relative',
            zIndex: 10,
            height: dimensions.screen.width + 40,
            width: '100%',
            maxWidth: 400,
            maxHeight: 440,
            marginBottom: 22,
        },
        slideContainer: {
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
        }
    });

    return (
        <View style={styles.container}>
            <Swiper
                innerContainerStyle={{ height: '100%', maxHeight: 440 }}
                minDistanceToCapture={2}
                containerStyle={{
                    maxHeight: 440,
                    height: '100%',
                }}
                controlsProps={{
                    prevTitle: '',
                    nextTitle: '',
                    dotActiveStyle: {
                        backgroundColor: '#602C8B',
                        width: 8,
                        height: 8,
                    },
                    DotComponent: ({ index, isActive, onPress }) =>
                        <View
                            onPress={onPress}
                            style={isActive
                                ? {
                                    width: 8,
                                    height: 8,
                                    backgroundColor: '#602C8B',
                                    borderRadius: 100,
                                    marginHorizontal: 4,
                                    bottom: -10
                                } : {
                                    width: 8,
                                    height: 8,
                                    backgroundColor: '#EBEBEB',
                                    borderRadius: 100,
                                    marginHorizontal: 4,
                                    bottom: -10
                                }}>
                        </View>,
                }}

            >
                {
                    swiperImages.map((item, index) => {
                        return (
                            <View style={[styles.slideContainer]} key={index}>
                                <Image
                                    source={{ uri: item.img_uri }}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        maxHeight: dimensions.screen.width > 400 ? 400 : dimensions.screen.width - 40,
                                        resizeMode: 'contain'
                                    }}
                                />
                            </View>
                        )
                    })
                }
            </Swiper>
        </View>
    );
};

export default ProductSlider;

