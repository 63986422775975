import React, { useState, useEffect } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Modal, Platform, ScrollView, TextInput } from 'react-native';
// import CheckBox from '@react-native-community/checkbox';
import { CheckBox } from 'react-native-elements'
import ModalWeb from 'modal-react-native-web';
import { connect } from 'react-redux';
import { editCurrentUser } from "../redux/user/user.actions";

import ButtonPrimary from "./buttonPrimary";
import ButtonSecondary from './buttonSecondary'

import { CloseModal, CheckboxTick } from "./svg/SnapioImages";

import globalStyles, { colors } from "../assets/globalStyles";

const AddPaymentModal = ({ modalTitle, content, visible, onPress, user, editCurrentUser, edit }) => {

    const [newPayment, setNewPayment] = useState({
        cardNumber: '',
        nameOnCard: '',
        expDate: '',
        ccv: ''
    })

    const [toggleCheckBox, setToggleCheckBox] = useState(false)

    return (
        <View>
            {
                Platform.OS === 'web' ?
                    <ModalWeb
                        visible={visible}
                        ariaHideApp={false}
                    >
                        <View style={styles.headerContainer}>
                            <Text style={styles.headerText}>{edit ? 'Edit card details' : modalTitle}</Text>
                            <TouchableOpacity style={styles.close} onPress={onPress}><CloseModal /></TouchableOpacity>
                        </View>
                        <ScrollView style={styles.container}>
                            <View style={{ marginTop: 17 }}>
                                <Text style={globalStyles.inputLabel}>Card number</Text>
                                <TextInput
                                    // TODO: Add correct types 
                                    style={globalStyles.inputField}
                                    value={newPayment.cardNumber}
                                    onChangeText={(text) => setNewPayment(newPayment.cardNumber = text)}
                                />
                                <Text style={globalStyles.inputLabel}>Name on card</Text>
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={newPayment.nameOnCard}
                                    onChangeText={(text) => setNewPayment(newPayment.nameOnCard = text)}
                                />
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <View style={{ width: '48%' }}>
                                        <Text style={globalStyles.inputLabel}>Exp. date</Text>
                                        <TextInput
                                            style={[globalStyles.inputField, { marginBottom: 8 }]}
                                            value={newPayment.expDate}
                                            placeholder={'DD/YY'}
                                            onChangeText={(text) => setNewPayment(newPayment.expDate = text)}
                                        />
                                    </View>
                                    <View style={{ width: '48%' }}>
                                        <Text style={globalStyles.inputLabel}>CVV</Text>
                                        <TextInput
                                            style={[globalStyles.inputField, { marginBottom: 8 }]}
                                            value={newPayment.ccv}
                                            placeholder={'123'}
                                            onChangeText={(text) => setNewPayment(newPayment.ccv = text)}
                                        />
                                    </View>
                                </View>
                                {/*  TODO: need to add action 'add to store' */}
                                {edit &&
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        {/* TODO: Need to create custom checkbox to fit style */}
                                        {/* <CheckBox
                                            disabled={false}
                                            value={toggleCheckBox}
                                            onValueChange={(newValue) => setToggleCheckBox(newValue)}
                                            tintColor='#000000'
                                        />
                                        <Text style={[colors.checkboxText]}>Set as your default payment method</Text> */}
                                        <CheckBox
                                            center
                                            title='Click Here'
                                            checked={toggleCheckBox}
                                            uncheckedIcon={<View style={{
                                                width: 32,
                                                height: 32,
                                                backgroundColor: colors.lightest,
                                                borderWidth: 1,
                                                borderColor: '#D9D9D9',
                                                borderRadius: 6,
                                            }}></View>}
                                            checkedIcon={
                                                <View style={{
                                                    width: 32,
                                                    height: 32,
                                                    backgroundColor: colors.brand1,
                                                    borderWidth: 1,
                                                    borderColor: colors.brand1,
                                                    borderRadius: 6,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}><CheckboxTick /></View>
                                            }
                                            containerStyle={globalStyles.checkboxContainer}
                                            textStyle={[globalStyles.checkboxText]}
                                            onPress={() => setToggleCheckBox(!toggleCheckBox)}
                                        />
                                    </View>}
                                <ButtonPrimary title="SAVE" />
                                {edit && <Text style={[globalStyles.p], { textAlign: 'center', opacity: 0.5, }}>Remove card</Text>}
                            </View>
                        </ScrollView>
                    </ModalWeb>
                    :
                    <Modal
                        visible={visible}
                    >
                        <View style={styles.headerContainer}>
                            <Text style={styles.headerText}>{edit ? 'Edit card details' : modalTitle}</Text>
                            <TouchableOpacity style={styles.close} onPress={onPress}><CloseModal /></TouchableOpacity>
                        </View>
                        <ScrollView style={styles.container}>
                            <View style={{ marginTop: 17 }}>
                                <Text style={globalStyles.inputLabel}>Card number</Text>
                                <TextInput
                                    // TODO: Add correct types 
                                    style={globalStyles.inputField}
                                    value={newPayment.cardNumber}
                                    onChangeText={(text) => setNewPayment(newPayment.cardNumber = text)}
                                />
                                <Text style={globalStyles.inputLabel}>Name on card</Text>
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={newPayment.nameOnCard}
                                    onChangeText={(text) => setNewPayment(newPayment.nameOnCard = text)}
                                />
                                <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <View style={{ width: '48%' }}>
                                        <Text style={globalStyles.inputLabel}>Exp. date</Text>
                                        <TextInput
                                            style={[globalStyles.inputField, { marginBottom: 8 }]}
                                            value={newPayment.expDate}
                                            placeholder={'DD/YY'}
                                            onChangeText={(text) => setNewPayment(newPayment.expDate = text)}
                                        />
                                    </View>
                                    <View style={{ width: '48%' }}>
                                        <Text style={globalStyles.inputLabel}>CVV</Text>
                                        <TextInput
                                            style={[globalStyles.inputField, { marginBottom: 8 }]}
                                            value={newPayment.ccv}
                                            placeholder={'123'}
                                            onChangeText={(text) => setNewPayment(newPayment.ccv = text)}
                                        />
                                    </View>
                                </View>
                                {/*  TODO: need to add action 'add to store' */}
                                {edit &&
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        {/* TODO: Need to create custom checkbox to fit style */}
                                        {/* <CheckBox
                                            disabled={false}
                                            value={toggleCheckBox}
                                            onValueChange={(newValue) => setToggleCheckBox(newValue)}
                                            tintColor='#000000'
                                        />
                                        <Text style={[colors.checkboxText]}>Set as your default payment method</Text> */}
                                        <CheckBox
                                            center
                                            title='Click Here'
                                            checked={toggleCheckBox}
                                            uncheckedIcon={<View style={{
                                                width: 32,
                                                height: 32,
                                                backgroundColor: colors.lightest,
                                                borderWidth: 1,
                                                borderColor: '#D9D9D9',
                                                borderRadius: 6,
                                            }}></View>}
                                            checkedIcon={
                                                <View style={{
                                                    width: 32,
                                                    height: 32,
                                                    backgroundColor: colors.brand1,
                                                    borderWidth: 1,
                                                    borderColor: colors.brand1,
                                                    borderRadius: 6,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}><CheckboxTick /></View>
                                            }
                                            containerStyle={globalStyles.checkboxContainer}
                                            textStyle={[globalStyles.checkboxText]}
                                            onPress={() => setToggleCheckBox(!toggleCheckBox)}
                                        />
                                    </View>}
                                <ButtonPrimary title="SAVE" />
                                {edit && <Text style={[globalStyles.p], { textAlign: 'center', opacity: 0.5, }}>Remove card</Text>}
                            </View>
                        </ScrollView>
                    </Modal>
            }
        </View>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.currentUser,
})

const mapDispatchToProps = (dispatch) => ({
    editCurrentUser: (userData, text) => dispatch(editCurrentUser(userData, text))
})

export default connect(null, mapDispatchToProps)(AddPaymentModal)


const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        width: '100%',
        backgroundColor: 'white',
    },
    headerContainer: {
        paddingTop: 16,
        paddingBottom: 17,
        borderBottomWidth: 1,
        borderColor: 'rgba(22,22,22,0.2)',
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: 20,
        textAlign: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 16,
        lineHeight: 22,
        color: '#161616',
    },
    close: {
        position: 'absolute',
        right: 20,
        alignSelf: 'center'
    }
})