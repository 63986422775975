import React, { useState, useEffect } from 'react'
import { View } from 'react-native'
import '@expo/match-media'
import axios from 'axios'

import { connect } from 'react-redux';
import { selectCountry } from '../redux/input/input.actions';

import DropDownPicker from 'react-native-dropdown-picker';

const CustomDropdowm = ({ selectCountry, ...props }) => {
    const [countriesCodes, setCountriesCodes] = useState([]);
    const [country, setCountry] = useState({});

    const [isFetching, setIsFetching] = useState(true);

    const { style } = props

    useEffect(() => {
        const instance = axios.create();
        delete instance.defaults.headers.common['Authorization'];

        instance.get('https://erp.test.snapio.shop/countries.json')
            .then(res => {
                const newArray = []
                res.data.map(item => {
                    if (item.iso2 === "LV") {
                        newArray.push({
                            label: '+' + JSON.stringify(item.phone_code),
                            value: item,
                            selected: true,
                        })
                        setCountry({
                            label: '+' + JSON.stringify(item.phone_code),
                            value: item,
                            selected: true,
                        })
                        selectCountry({
                            label: '+' + JSON.stringify(item.phone_code),
                            value: item,
                            selected: true,
                        })
                    } else {
                        newArray.push({
                            label: '+' + JSON.stringify(item.phone_code),
                            value: item,
                        })
                    }
                })
                setCountriesCodes(newArray);
                return res;
            })
            .then(res => {
                setIsFetching(false);
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <View style={[{ zIndex: 50, marginBottom: 15 }]}>
            {
                !isFetching &&
                <DropDownPicker
                    items={countriesCodes}
                    containerStyle={{ height: 48 }}
                    style={{
                        height: 48,
                        backgroundColor: '#EBEBEB',
                        borderRadius: 8,
                        color: '#2E414D',
                        fontFamily: 'Pragmatica-Book',
                        lineHeight: 22,
                        fontSize: 16,
                        borderWidth: 0,
                    }}
                    itemStyle={{
                        justifyContent: 'flex-start'
                    }}
                    dropDownStyle={{ backgroundColor: '#EBEBEB' }}
                    onChangeItem={item => {
                        setCountry({ item })
                        selectCountry(item)
                    }}
                />
            }
        </View>
    )
}

const mapDispatchToProps = (dispatch) => ({
    selectCountry: (item) => dispatch(selectCountry(item))
})

export default connect(null, mapDispatchToProps)(CustomDropdowm);