import { PRODUCT_LIST, PRODUCT_LIST_FAIL, PRODUCT_LIST_SUCCESS, SINGLE_PRODUCT, SINGLE_PRODUCT_SUCCESS, SINGLE_RODUCT_FAIL, SELECT_ADDON } from './product.types'
import { selectAddon, setSelect } from './product.utils'

const INITIAL_STATE = {
    productList: {},
    singleProduct: {},
    error: false,
    isFetching: false,
}

const productReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PRODUCT_LIST:
            return {
                ...state,
                isFetching: true
            }
        case PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                isFetching: false,
                productList: action.data
            }
        case PRODUCT_LIST_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case SINGLE_PRODUCT:
            return {
                ...state,
                isFetching: true
            }
        case SINGLE_PRODUCT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                singleProduct: setSelect(action.data)
            }
        case SINGLE_RODUCT_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case SELECT_ADDON:
            return {
                ...state,
                singleProduct: selectAddon(state.singleProduct, action.data)
            }
        default:
            return state;
    }
}

export default productReducer