import React, { useState, useEffect } from "react";
import {
    View,
    Text,
    StyleSheet,
    TouchableOpacity,
    ScrollView,
    TextInput,
    Image,
} from 'react-native';

import i18n from 'i18n-js'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { getDeliveryAddresses, setDefault, deleteAddress } from '../redux/delivery/delivery.actions'

import ButtonSecondary from "../components/buttonSecondary";
import AddressModal from '../components/addressModal'

import { BackButton } from "../components/svg/SnapioImages";

import globalStyles, { colors } from "../assets/globalStyles";

const MyAddressesPage = ({ getDeliveryAddresses, deliveryAddresses, setDefault, navigation, deleteAddress }) => {

    const [addressModal, setAddressModal] = useState({
        toggle: false,
        edit: false
    });

    const toggleAddressModal = (editVal = false) => {
        setAddressModal({
            ...addressModal,
            toggle: !addressModal.toggle,
            edit: editVal,
        });
    }

    useEffect(() => {
        getDeliveryAddresses()
    }, [])

    return (
        <View style={{ flex: 1 }}>
            <View style={globalStyles.headerContainer}>
                <TouchableOpacity style={globalStyles.headerBackButton} onPress={() => navigation.goBack()}><BackButton /></TouchableOpacity>
                <Text style={globalStyles.headerText}>{i18n.t("address.my_addresses")}</Text>
            </View>
            <View style={{ flex: 2 }}>
                <ScrollView style={[styles.container, { marginTop: 24 }]}>
                    {
                        deliveryAddresses && deliveryAddresses.map((item, index) => {
                            return (
                                <View key={index} style={[globalStyles.card, { marginBottom: 16, marginHorizontal: 20 }]}>
                                    <View style={{
                                        paddingTop: 16,
                                        paddingHorizontal: 16,
                                        paddingBottom: 24,
                                    }}>
                                        <Text style={[globalStyles.p, { marginBottom: 0 }]}>{item.name}</Text>
                                        <Text style={[globalStyles.p, { marginBottom: 0 }]}>{item.title_full_address}</Text>
                                    </View>
                                    <View style={[
                                        {
                                            paddingHorizontal: 16,
                                            paddingTop: 12,
                                            paddingBottom: 12,
                                            borderTopColor: colors.seperator,
                                            borderTopWidth: 1,
                                        },
                                        item.is_default ?
                                            {
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }
                                            : null
                                    ]}>
                                        <TouchableOpacity onPress={() => { deleteAddress(item.id) }}>
                                            <Text style={[globalStyles.link]}>{i18n.t("delete")}</Text>
                                        </TouchableOpacity>
                                        {/* <TouchableOpacity onPress={() => { toggleAddressModal(item.id) }}>
                                            <Text style={[globalStyles.link]}>Edit</Text>
                                        </TouchableOpacity> */}
                                        {
                                            item.is_default
                                                ?
                                                <Text style={[globalStyles.tag, { marginLeft: 'auto' }]}>{i18n.t("address.default_address")}</Text>
                                                :
                                                <TouchableOpacity onPress={() => setDefault(item.id)}>
                                                    <Text style={[globalStyles.link]}>{i18n.t("address.set_default")}</Text>
                                                </TouchableOpacity>
                                        }
                                    </View>
                                </View>
                            )
                        })
                    }
                    <AddressModal
                        modalTitle={i18n.t("address.address_modal_title")}
                        visible={addressModal.toggle}
                        edit={addressModal.edit}
                        onPress={() => {
                            toggleAddressModal();
                        }}
                    />
                </ScrollView>
                <ButtonSecondary title={i18n.t("address.add_new_address")} style={{ marginTop: 'auto', width: 'auto', marginHorizontal: 20, }} onPress={() => {
                    toggleAddressModal();
                }} />
            </View>
        </View>
    )
}

const mapStateToProps = (state) => ({
    deliveryAddresses: state.deliveryAddress.addresses,
})

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({ getDeliveryAddresses }, dispatch),
    ...bindActionCreators({ deleteAddress }, dispatch),
    ...bindActionCreators({ setDefault }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(MyAddressesPage)


const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
})