export const API_ADD_ITEM = 'API_ADD_ITEM';
export const API_ADD_ITEM_SUCCESS = 'API_ADD_ITEM_SUCCESS';
export const API_ADD_ITEM_FAIL = 'API_ADD_ITEM_FAIL';

export const LOAD_CART = 'LOAD_CART';
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS';
export const LOAD_CART_FAIL = 'LOAD_CART_FAIL';

export const INCREASE_QUANTITY = 'INCREASE_QUANTITY';
export const INCREASE_QUANTITY_SUCCESS = 'INCREACE_QUANTITY_SUCCESS';
export const INCREASE_QUANTITY_FAIL = 'INCREASE_QUANTITY_FAIL';

export const DECREASE_QUANTITY = 'DECREASE_QUANTITY';
export const DECREASE_QUANTITY_SUCCESS = 'DECREASE_QUANTITY_SUCCESS';  
export const DECREASE_QUANTITY_FAIL = 'DECREASE_QUANTITY_FAIL';

export const DELETE_ITEM = 'DELETE_ITEM';
export const DELETE_ITEM_SUCCESS = 'DELETE_ITEM_SUCCESS';
export const DELETE_ITEM_FAIL = 'DELETE_ITEM_FAIL';