import {
    INCREASE_QUANTITY, INCREASE_QUANTITY_FAIL, INCREASE_QUANTITY_SUCCESS,
    DECREASE_QUANTITY, DECREASE_QUANTITY_FAIL, DECREASE_QUANTITY_SUCCESS,
    API_ADD_ITEM, API_ADD_ITEM_FAIL, API_ADD_ITEM_SUCCESS,
    LOAD_CART, LOAD_CART_FAIL, LOAD_CART_SUCCESS, DELETE_ITEM, DELETE_ITEM_FAIL, DELETE_ITEM_SUCCESS
} from './cart.types'
import store from '../store'
import axios from 'axios'
import { calculateDeliveryPrice, getOrder } from '../order/order.actions'

export const loadCart = () => {
    const merchantId = localStorage.getItem('merchantId');
    const orderToken = localStorage.getItem('orderToken') || null;
    return (dispatch) => {
        dispatch(loadItemsToCart())
        return (
            axios.get(`/cart/${merchantId}/${orderToken}`)
                .then(res => {
                    if (res.status === 200) {
                        if (orderToken === null) {
                            localStorage.setItem('orderToken', res.data.payload.order_token)
                        }
                        return dispatch(loadItemsToCartSuccess(res.data.payload))
                    }
                })
                .catch(err => dispatch(loadItemsToCartFail()))
        )
    }
}

export const addItem = (productId, productQuantity) => {
    const orderToken = localStorage.getItem('orderToken');
    const addons = []
    store.getState().product.singleProduct.addons.map(addon => {
        addon.options.map(option => {
            if (option.selected) {
                addons.push(option.id);
            }
        })
    })
    return (dispatch) => {
        dispatch(addItemToCart())
        return (
            axios.post('/cart/', {
                "order_token": orderToken ? orderToken : null,
                "id": productId,
                "quantity": productQuantity,
                "addons": addons ? addons : null
            })
                .then(res => {
                    if (res.data.success) {
                        localStorage.setItem('orderToken', res.data.payload.order_token)
                        dispatch(addItemToCartSuccess(res.data.payload));
                    }
                })
                .catch(err => dispatch(addItemToCartFail()))
        )
    }
}
export const increaseQuantity = (item) => {
    const { quantity, id: orderItemId } = item;
    const orderToken = localStorage.getItem('orderToken');
    return (dispatch) => {
        dispatch(increaseItemQuantity())
        return (
            axios.patch(`/order/${orderToken}/items/${orderItemId}`, { "quantity": 1 + quantity })
                .then(res => {
                    const currentCart = store.getState().cart.cartList;
                    currentCart.items.map(item => {
                        if (item.id === res.data.id) {
                            item.quantity = res.data.quantity
                            return item
                        } else {
                            return item
                        }
                    })
                    dispatch(increaseItemQuantitySuccess(currentCart));
                    dispatch(getOrder());
                })
                .catch(err => {
                    return dispatch(increaseItemQuantityFail())
                })
        )
    }
}
export const decreaseQuantity = (item) => {
    const { quantity, id: orderItemId } = item;
    const orderToken = localStorage.getItem('orderToken');
    return (dispatch) => {
        dispatch(decreaseItemQuantity())
        return (
            axios.patch(`/order/${orderToken}/items/${orderItemId}`, { "quantity": quantity - 1 })
                .then(res => {
                    const currentCart = store.getState().cart.cartList;
                    currentCart.items.map(item => {
                        if (item.id === res.data.id) {
                            item.quantity = res.data.quantity
                            return item
                        } else {
                            return item
                        }
                    })
                    dispatch(decreaseItemQuantitySuccess(currentCart));
                    dispatch(getOrder());
                })
                .catch(err => {
                    return dispatch(decreaseItemQuantityFail())
                })
        )
    }
}
export const deleteItem = (item) => {
    const { id: orderItemId } = item;
    const orderToken = localStorage.getItem('orderToken');
    return (dispatch) => {
        dispatch(deleteItemFromCart())
        return (
            axios.delete(`/order/${orderToken}/items/${orderItemId}`)
                .then(res => {
                    dispatch(loadCart());
                    dispatch(deleteItemFromCartSuccess());
                })
                .catch(err => {
                    console.log(err);
                    return dispatch(deleteItemFromCartFail())
                })
        )
    }
}
const addItemToCart = () => {
    return {
        type: API_ADD_ITEM
    }
}
const addItemToCartSuccess = (data) => {
    return {
        type: API_ADD_ITEM_SUCCESS,
        data
    }
}
const addItemToCartFail = () => {
    return {
        type: API_ADD_ITEM_FAIL
    }
}
const loadItemsToCart = () => {
    return {
        type: LOAD_CART
    }
}
const loadItemsToCartSuccess = (data) => {
    return {
        type: LOAD_CART_SUCCESS,
        data: { ...data }
    }
}
const loadItemsToCartFail = () => {
    return {
        type: LOAD_CART_FAIL
    }
}
const increaseItemQuantity = () => {
    return {
        type: INCREASE_QUANTITY
    }
}
const increaseItemQuantitySuccess = (data) => {
    return {
        type: INCREASE_QUANTITY_SUCCESS,
        data: { ...data }
    }
}
const increaseItemQuantityFail = () => {
    return {
        type: INCREASE_QUANTITY_FAIL
    }
}
const decreaseItemQuantity = () => {
    return {
        type: DECREASE_QUANTITY
    }
}
const decreaseItemQuantitySuccess = (data) => {
    return {
        type: DECREASE_QUANTITY_SUCCESS,
        data: { ...data }
    }
}
const decreaseItemQuantityFail = () => {
    return {
        type: DECREASE_QUANTITY_FAIL
    }
}
const deleteItemFromCart = () => {
    return {
        type: DELETE_ITEM
    }
}
const deleteItemFromCartSuccess = () => {
    return {
        type: DELETE_ITEM_SUCCESS,
    }
}
const deleteItemFromCartFail = () => {
    return {
        type: DELETE_ITEM_FAIL
    }
}