import {
    CALCULATE_DELIVERY_PRICE, CALCULATE_DELIVERY_PRICE_FAIL, CALCULATE_DELIVERY_PRICE_SUCCESS,
    GET_DELIVERY_METHODS, GET_DELIVERY_METHODS_FAIL, GET_DELIVERY_METHODS_SUCCESS,
    SELECT_DELIVERY_METHOD, SELECT_DELIVERY_METHOD_FAIL, SELECT_DELIVERY_METHOD_SUCCESS,
    GET_ORDER, GET_ORDER_FAIL, GET_ORDER_SUCCESS,
    GET_PAYMENT_METHODS, GET_PAYMENT_METHODS_FAIL, GET_PAYMENT_METHODS_SUCCESS,
    SELECT_PAYMENT_METHOD, SELECT_PAYMENT_METHOD_FAIL, SELECT_PAYMENT_METHOD_SUCCESS,
    GET_PICKUP_POINTS, GET_PICKUP_POINTS_FAIL, GET_PICKUP_POINTS_SUCCESS,
    SELECT_PICKUP_POINT, SELECT_PICKUP_POINT_FAIL, SELECT_PICKUP_POINT_SUCCESS,
    SELECT_DELIVERY_ADDRESS, SELECT_DELIVERY_ADDRESS_FAIL, SELECT_DELIVERY_ADDRESS_SUCCESS,
} from './order.types';

const initialState = {
    order: [],
    deliveryMethods: [],
    pickupPoints: [],
    calculatePrice: [],
    paymentMethods: [],
    isFetching: false,
    error: false,
    errorFields: {}
}

const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ORDER:
            return {
                ...state,
                isFetching: true
            }
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                order: action.data
            }
        case GET_ORDER_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case GET_DELIVERY_METHODS:
            return {
                ...state,
                isFetching: true
            }
        case GET_DELIVERY_METHODS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                deliveryMethods: action.data
            }
        case GET_DELIVERY_METHODS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case GET_PICKUP_POINTS:
            return {
                ...state,
                isFetching: true
            }
        case GET_PICKUP_POINTS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                pickupPoints: action.data
            }
        case GET_PICKUP_POINTS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case CALCULATE_DELIVERY_PRICE:
            return {
                ...state,
                isFetching: true
            }
        case CALCULATE_DELIVERY_PRICE_SUCCESS:
            return {
                ...state,
                isFetching: false,
                calculatePrice: action.data
            }
        case CALCULATE_DELIVERY_PRICE_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case GET_PAYMENT_METHODS:
            return {
                ...state,
                isFetching: true
            }
        case GET_PAYMENT_METHODS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                paymentMethods: action.data
            }
        case GET_PAYMENT_METHODS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case SELECT_PAYMENT_METHOD:
            return {
                ...state,
                isFetching: true
            }
        case SELECT_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                isFetching: false,
                order: action.data
            }
        case SELECT_PAYMENT_METHOD_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case SELECT_DELIVERY_METHOD:
            return {
                ...state,
                isFetching: true
            }
        case SELECT_DELIVERY_METHOD_SUCCESS:
            return {
                ...state,
                isFetching: false
            }
        case SELECT_DELIVERY_METHOD_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case SELECT_PICKUP_POINT:
            return {
                ...state,
                isFetching: true
            }
        case SELECT_PICKUP_POINT_SUCCESS:
            return {
                ...state,
                isFetching: false
            }
        case SELECT_PICKUP_POINT_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case SELECT_DELIVERY_ADDRESS:
            return {
                ...state,
                isFetching: true
            }
        case SELECT_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                isFetching: false
            }
        case SELECT_DELIVERY_ADDRESS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        default:
            return state;
    }
}

export default orderReducer;