import {
    ADD_PAYMENT_METHOD, SELECT_METHOD, SET_DEFAULT,
    GET_CARDS, GET_CARDS_FAIL, GET_CARDS_SUCCESS,
    GET_CARD, GET_CARD_FAIL, GET_CARD_SUCCESS,
    REMOVE_CARD, REMOVE_CARD_FAIL,
    SET_CARD_AS_DEFAULT
} from './payment.types';
import { addSelected, selectMethod, setDefault } from './payment.utils';

const INITIAL_STATE = {
    cards: [],
    isFetching: false,
    error: false
}

const paymentReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_CARDS:
            return {
                ...state,
                isFetching: true
            }
        case GET_CARDS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                cards: action.data
            }
        case GET_CARDS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case REMOVE_CARD:
            return {
                ...state,
                isFetching: true
            }
        case REMOVE_CARD_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case SET_CARD_AS_DEFAULT:
            return {
                ...state,
                isFetching: true
            }
        case ADD_PAYMENT_METHOD:
            return {
                ...state,
                paymentMethods: addSelected(state.paymentMethods, action.payload)
            }
        case SELECT_METHOD:
            return {
                ...state,
                paymentMethods: selectMethod(state.paymentMethods, action.payload)
            }
        case SET_DEFAULT:
            return {
                ...state,
                paymentMethods: setDefault(state.paymentMethods, action.payload)
            }
        default:
            return state
    }
}

export default paymentReducer;