import React, { useState, useEffect } from 'react';
import { StyleSheet, Button, View, Image, Text, ScrollView, TouchableOpacity, Platform, Dimensions } from 'react-native';

import HTML from "react-native-render-html";

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addItem } from '../redux/cart/cart.actions';
import { getProductList } from '../redux/product/product.actions';
import { getCards } from '../redux/payment/payment.actions';
import { loadCart } from '../redux/cart/cart.actions'
// Global styles
import globalStyles from "../assets/globalStyles";
// Our components
import SnapioHeader from "../components/header";
import NewSnapFooter from "../components/newSnapFooter";
import SnapioFooter from "../components/footer";
import ProductList from "../components/productList";
import GoToCartBtn from "../components/goToCartBtn";
// SVG
import { SendBox } from "../components/svg/SnapioImages";

const ListProduct = ({ navigation, addItem, getProductList, merchantPage, loadCart, getCards }) => {

    useEffect(() => {
        getProductList()
            .then(() => loadCart())
            .then(() => getCards())
    }, [])

    return (
        <View style={{ flex: 1, justifyContent: 'flex-start', width: '100%', height: '100%' }}>
            <SnapioHeader navigation={navigation} />
            <ScrollView style={[
                { width: '100%' },
                Platform.OS === 'web' && { maxHeight: Dimensions.get('window').height - 63 - 62 }
            ]} contentContainerStyle={styles.container}>
                {/* Company logo */}
                {merchantPage.merchant && <Image style={styles.companyLogo} source={{ uri: merchantPage.merchant.logo.url }} />}
                {merchantPage.merchant && <Text style={styles.companyTitle}>{merchantPage.merchant.title}</Text>}
                {merchantPage.title && <Text style={styles.productTitle}>{merchantPage.title}</Text>}
                {merchantPage.description &&
                    <HTML
                        source={{ html: merchantPage.description }}
                        tagsStyles={{
                            div: {
                                fontFamily: 'Pragmatica-Book',
                                fontSize: 21,
                                lineHeight: 32,
                                textAlign: 'center',
                                color: '#161616'
                            }
                        }}
                    />}
                {/* <Image style={styles.companyLogo} source={require('../assets/images/product/logo.png')} />
                <Text style={styles.companyTitle}>MADARA COSMETICS</Text>
                <Text style={styles.productTitle}>Īpašie Snapio piedāvājumi</Text>
                <Text style={{
                    fontFamily: 'Pragmatica-Book',
                    fontSize: 21,
                    lineHeight: 32,
                    textAlign: 'center',
                    color: '#161616'
                }}>
                    Multivitamīnu-antioksidantu spēks no zilenes, avenes, dzērvenes sēklu eļļām ir sinerģiski sajaukts.
                </Text> */}
                <ProductList navigation={navigation} />
                <SnapioFooter />
            </ScrollView>
            {/* <NewSnapFooter /> */}
            <GoToCartBtn onPress={() => navigation.navigate('MyStack', { screen: 'CartView' })} />
        </View>
    )
}

const mapStateToProps = (state) => ({
    merchantPage: state.product.productList,
})

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({ getProductList }, dispatch),
    ...bindActionCreators({ getCards }, dispatch),
    ...bindActionCreators({ loadCart }, dispatch),
    addItem: (item) => dispatch(addItem(item)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ListProduct);

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 16,
        width: '100%',
        backgroundColor: 'white',
    },
    companyLogo: {
        minWidth: 80,
        minHeight: 80,
        width: 80,
        height: 80,
        resizeMode: 'contain',
        borderRadius: 100,
    },
    companyTitle: {
        marginTop: 16,
        marginBottom: 4,
        fontSize: 12,
        color: '#6C6C6C',
        lineHeight: 20,
        fontFamily: 'Pragmatica-Book',
        textAlign: 'center',
    },
    productTitle: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 34,
        lineHeight: 44,
        letterSpacing: -0.4,
        color: '#161616',
        textAlign: 'center',
        marginBottom: 24,
    },
    mainPrice: {
        color: '#161616',
        fontFamily: 'Pragmatica-Bold',
        fontSize: 22,
        lineHeight: 28,
    },
    optionTitle: {
        color: '#6C6C6C',
        fontSize: 12,
        lineHeight: 16,
        fontFamily: 'Pragmatica-Book',
        alignSelf: 'flex-start',
        marginBottom: 17,
        marginTop: 21,
    },
    infoLogo: {
        width: 24,
        height: 24,
        minWidth: 24,
        minHeight: 24,
        resizeMode: 'cover',
        marginRight: 8,
        borderRadius: 100,
    },
    infoContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'flex-start',
        marginBottom: 20,
    },
    infoTitle: {
        fontFamily: 'Pragmatica-Book',
        fontSize: 14,
        lineHeight: 20,
        color: '#161616'
    },
    sendBox: {
        marginRight: 8,
    },
    infoUnderline: {
        flexDirection: 'column',
        position: 'relative',
    },
    infoUnderlineLine: {
        height: 1,
        width: '100%',
        backgroundColor: '#161616',
        position: 'absolute',
        left: 0,
        bottom: 3,
    },
    descriptionContainer: {
        width: '100%',
        marginBottom: 12,
    }
})