import { PRODUCT_LIST, PRODUCT_LIST_FAIL, PRODUCT_LIST_SUCCESS, SINGLE_PRODUCT, SINGLE_PRODUCT_SUCCESS, SINGLE_RODUCT_FAIL, SELECT_ADDON } from './product.types'
// import { loadCart } from '../cart/cart.actions'
import axios from 'axios'
// TODO: Pass link as param or get link here? 
export const getProductList = () => {
    return (dispatch) => {
        dispatch(productList())
        return (
            axios.get('/product-list/e0fdb841-e1d8-4131-9510-d6e70a1c23cf')
                .then(res => {
                    if (res.status === 200) {
                        localStorage.setItem('merchantId', res.data.merchant.id);
                        // dispatch(loadCart());
                        return dispatch(productListSuccess(res.data))
                    }
                })
                .catch(err => dispatch(productListFail()))
        )
    }
}

export const getSingleProduct = (id) => {
    return (dispatch) => {
        dispatch(singleProduct())
        return (
            axios.get('/product/' + id)
                .then(res => {
                    if (res.status === 200) {
                        return dispatch(singleProductSuccess(res.data))
                    }
                })
                .catch(err => dispatch(singleProductFail()))
        )
    }
}

export const selectAddon = (addonListId, addonOptionId) => ({
    type: SELECT_ADDON,
    data: { addonListId, addonOptionId }
})

const productList = () => {
    return {
        type: PRODUCT_LIST
    }
}

const productListSuccess = (data) => {
    return {
        type: PRODUCT_LIST_SUCCESS,
        data
    }
}

const productListFail = () => {
    return {
        type: PRODUCT_LIST_FAIL
    }
}

const singleProduct = () => {
    return {
        type: SINGLE_PRODUCT
    }
}

const singleProductSuccess = (data) => {
    return {
        type: SINGLE_PRODUCT_SUCCESS,
        data
    }
}

const singleProductFail = () => {
    return {
        type: SINGLE_RODUCT_FAIL
    }
}

