import React, { useState, useEffect } from 'react';
import { StyleSheet, View, Text, ScrollView, TouchableOpacity, StatusBar, Dimensions, Platform } from 'react-native';
import i18n from 'i18n-js'

// Testing redux
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getOrder } from '../redux/order/order.actions'

import globalStyles from "../assets/globalStyles";

import CartProductList from '../components/cartProductList';
import ButtonPrimary from '../components/buttonPrimary';
import OptionPaymentMethods from '../components/optionPaymentMethods'
import OptionListPayments from '../components/optionListPayments';
import OptionListDelivery from '../components/optionListDelivery';
import ContactInfoModal from "../components/contactInfoModal";
import AddPaymentModal from '../components/addNewPaymentModal';

// SVG
import { CloseModal } from "../components/svg/SnapioImages";

const CartViewStack = ({ navigation, order, user, getOrder }) => {
    const [paymentModal, setPaymentModal] = useState(false);
    const [contactModal, setContactModalVisible] = useState(false);

    useEffect(() => {
        getOrder();
    }, [])

    return (
        <View style={{ flex: 1, backgroundColor: 'white' }}>
            <View style={globalStyles.headerContainer}>
                <Text style={globalStyles.headerText}>{i18n.t("cart.your_cart")}</Text>
                <TouchableOpacity style={globalStyles.headerCloseButton} onPress={() => navigation.goBack()}><CloseModal /></TouchableOpacity>
            </View>
            <ScrollView style={[styles.container, Platform.OS === 'web' && { maxHeight: Dimensions.get('window').height - 56 }]}>
                {
                    order.items && order.items.length !== 0
                        ?
                        <View style={{ paddingHorizontal: 20 }}>
                            <CartProductList
                            // data={items}
                            />
                        </View>
                        :
                        <View style={{ flex: 1, paddingHorizontal: 20 }}>
                            <Text>{i18n.t("cart.items_not_found")}</Text>
                        </View>
                }
                <View style={{ paddingHorizontal: 20, paddingBottom: 30, zIndex: 10 }}>
                    <View style={[styles.sectionHeader, { marginBottom: 0 }]}>
                        <Text style={[globalStyles.h3, { marginVertical: 0 }]}>{i18n.t("cart.delivery")}</Text>
                    </View>
                    <View style={{ zIndex: 11 }}>
                        <OptionListDelivery />
                    </View>
                </View>
                <View style={{ paddingHorizontal: 20, paddingBottom: 30 }}>
                    <View style={[styles.sectionHeader, { marginBottom: 0 }]}>
                        <Text style={[globalStyles.h3, { marginVertical: 0 }]}>Payment Method</Text>
                    </View>
                    <View>
                        <OptionPaymentMethods />
                    </View>
                </View>
                <View style={{ paddingHorizontal: 20, paddingBottom: 30 }}>
                    <View style={[styles.sectionHeader, { marginBottom: 18 }]}>
                        <Text style={[globalStyles.h3, { marginBottom: 0 }]}>{i18n.t("cart.contact_information")}</Text>
                        <Text style={[globalStyles.link, { margin: 0 }]} onPress={() => { setContactModalVisible(!contactModal) }}>{i18n.t("cart.edit")}</Text>
                    </View>
                    <View>
                        {user?.first_name && <Text style={[globalStyles.p, { marginBottom: 8 }]}>{user.first_name} {user.last_name}</Text>}
                        {user?.email && <Text style={[globalStyles.p, { marginBottom: 8 }]}>{user.email}</Text>}
                    </View>
                </View>
                {
                    order?.payment_method?.type === 'card_online' &&
                    <View style={{ paddingHorizontal: 20, paddingBottom: 30 }}>
                        <View style={[styles.sectionHeader, { marginBottom: 0 }]}>
                            <Text style={[globalStyles.h3, { marginBottom: 0 }]}>{i18n.t("cart.payment")}</Text>
                            <Text
                                style={[globalStyles.link, { marginVertical: 0 }]}
                                onPress={() => { setPaymentModal(!paymentModal) }}
                            >
                                {i18n.t("cart.add_new_method")}
                            </Text>
                        </View>
                        <View>
                            <OptionListPayments />
                        </View>
                    </View>
                }
                <View style={{ backgroundColor: '#F6F6F6', paddingHorizontal: 20 }}>
                    <View style={[styles.sectionHeader, { paddingBottom: 22 }]}>
                        <Text style={[globalStyles.h3, { marginVertical: 0 }]}>{i18n.t("cart.checkout")}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginVertical: 10 }}>
                        <Text style={[globalStyles.tag, { marginVertical: 0 }]}>{i18n.t("cart.subtotal")}</Text>
                        <Text style={[globalStyles.p, { marginVertical: 0 }]}>€ {order.products_total}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginVertical: 10 }}>
                        <Text style={[globalStyles.tag, { marginVertical: 0 }]}>{i18n.t("cart.delivery")}</Text>
                        <Text style={[globalStyles.p, { marginVertical: 0 }]}>€ {order.delivery_price}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginVertical: 10 }}>
                        <Text style={[globalStyles.p, { marginVertical: 0 }]}>{i18n.t("cart.total_to_pay")}</Text>
                        <Text style={[globalStyles.h4, { marginVertical: 0 }]}>€ {order.total_amount}</Text>
                    </View>
                    <ButtonPrimary title="PLACE ORDER" onPress={() => navigation.navigate('MyStack', { screen: 'Order Complete' })} />
                </View>
                <ContactInfoModal modalTitle='Edit contact info' visible={contactModal} onPress={() => { setContactModalVisible(!contactModal) }} />
                <AddPaymentModal modalTitle='Add new payment method' visible={paymentModal} onPress={() => { setPaymentModal(!paymentModal) }} />
            </ScrollView>
        </View>
    )
}

const mapStateToProps = (state) => ({
    // items: state.order.order.items,
    order: state.order.order,
    user: state.user.userData,
})

const mapDispatchToProps = (dispatch) => ({
    ...bindActionCreators({ getOrder }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CartViewStack);

const styles = StyleSheet.create({
    container: {
        width: '100%',
        backgroundColor: 'white',
        flex: 1,
    },
    headerContainer: {
        paddingTop: 16,
        paddingBottom: 17,
        borderBottomWidth: 1,
        borderColor: 'rgba(22,22,22,0.2)',
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: 20,
        textAlign: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 16,
        lineHeight: 22,
        color: '#161616',
    },
    close: {
        position: 'absolute',
        right: 20,
        alignSelf: 'center'
    },
    sectionHeader: {
        paddingVertical: 18,
        borderBottomColor: 'rgba(22,22,22,0.1)',
        borderBottomWidth: 1,
        marginBottom: 10,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    }
})