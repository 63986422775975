export const selectMethod = (paymentMethods, selectedPaymentMethod) => {
    paymentMethods.map(item => {
        item.selected = false;
        if (item.id === selectedPaymentMethod.id) {
            item.selected = !item.selected
            return item;
        }
        return item;
    })

    return [...paymentMethods]
}

export const setDefault = (paymentMethods, selectedPaymentMethod) => {
    paymentMethods.map(item => {
        item.is_default = false;
        if (item.id === selectedPaymentMethod.id) {
            item.is_default = !item.is_default
            return item;
        }
        return item;
    })

    return [...paymentMethods]
}

export const addSelected = (paymentMethods, newItem) => {
    paymentMethods.map(item => {
        item.is_default ? item.selected = true : item.selected = false;
        return item;
    })

    return [...paymentMethods]
}