import React from "react";
import { View, Text, StyleSheet, TouchableOpacity, Modal, Platform, ScrollView, TextInput } from 'react-native';
import ModalWeb from 'modal-react-native-web';

import { connect } from 'react-redux';
import { editCurrentUser } from "../redux/user/user.actions";

import ButtonPrimary from "./buttonPrimary";

import { CloseModal } from "./svg/SnapioImages";

import globalStyles from "../assets/globalStyles";

const ContactInfoModal = ({ modalTitle, content, visible, onPress, user, editCurrentUser }) => (
    <View>
        {
            Platform.OS === 'web' ?
                <ModalWeb
                    visible={visible}
                    ariaHideApp={false}
                >
                    <View style={styles.headerContainer}>
                        <Text style={styles.headerText}>{modalTitle}</Text>
                        <TouchableOpacity style={styles.close} onPress={onPress}><CloseModal /></TouchableOpacity>
                    </View>
                    <ScrollView style={styles.container}>
                        <View style={{ marginTop: 17 }}>
                            <Text style={globalStyles.inputLabel}>Name Surname</Text>
                            {
                                user?.first_name && user?.last_name &&
                                <TextInput
                                    style={globalStyles.inputField}
                                    value={user.first_name + ' ' + user.last_name}
                                />
                            }

                            <Text style={globalStyles.inputLabel}>Address</Text>
                            <TextInput
                                style={globalStyles.inputField}
                                value={user.address}
                                onChangeText={text => {
                                    user.address = text
                                    editCurrentUser(user)
                                }}
                            />
                            <Text style={globalStyles.inputLabel}>E-mail</Text>
                            <TextInput
                                style={[globalStyles.inputField, { marginBottom: 8 }]}
                                value={user.email}
                                onChangeText={text => {
                                    user.email = text
                                    editCurrentUser(user)
                                }}
                            />
                            <Text style={globalStyles.tag}>We will send invoice to this e-mail</Text>
                            <Text style={[globalStyles.link, { marginTop: 15, marginBottom: 17 }]}>Add Company information</Text>
                            <ButtonPrimary marginTop={0} title="SAVE" />
                        </View>
                    </ScrollView>
                </ModalWeb>
                :
                <Modal
                    visible={visible}
                >
                    <View style={styles.headerContainer}>
                        <Text style={styles.headerText}>{modalTitle}</Text>
                        <TouchableOpacity style={styles.close} onPress={onPress}><CloseModal /></TouchableOpacity>
                    </View>
                    <ScrollView style={styles.container}>
                        <View style={{ marginTop: 17 }}>
                            <Text style={globalStyles.inputLabel}>Name Surname</Text>
                            <TextInput
                                style={globalStyles.inputField}
                                value={user.first_name + ' ' + user.last_name}
                            />
                            <Text style={globalStyles.inputLabel}>Address</Text>
                            <TextInput
                                style={globalStyles.inputField}
                                value={user.address}
                            />
                            <Text style={globalStyles.inputLabel}>E-mail</Text>
                            <TextInput
                                style={[globalStyles.inputField, { marginBottom: 8 }]}
                                value={user.email}
                                onChangeText={text => {
                                    user.email = text
                                    editCurrentUser(user)
                                }}
                            />
                            <Text style={globalStyles.tag}>We will send invoice to this e-mail</Text>
                            <ButtonPrimary title="SAVE" />
                        </View>
                    </ScrollView>
                </Modal>
        }
    </View>
)

const mapStateToProps = (state) => ({
    user: state.user.userData,
})

const mapDispatchToProps = (dispatch) => ({
    editCurrentUser: (userData, text) => dispatch(editCurrentUser(userData, text))
})

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfoModal)


const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 20,
        width: '100%',
        backgroundColor: 'white',
    },
    headerContainer: {
        paddingTop: 16,
        paddingBottom: 17,
        borderBottomWidth: 1,
        borderColor: 'rgba(22,22,22,0.2)',
        flexDirection: 'row',
        width: '100%',
        paddingHorizontal: 20,
        textAlign: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontFamily: 'Pragmatica-Bold',
        fontSize: 16,
        lineHeight: 22,
        color: '#161616',
    },
    close: {
        position: 'absolute',
        right: 20,
        alignSelf: 'center'
    }
})