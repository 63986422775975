import {
    ADD_PAYMENT_METHOD, SELECT_METHOD, SET_DEFAULT,
    GET_CARDS, GET_CARDS_FAIL, GET_CARDS_SUCCESS,
    GET_CARD, GET_CARD_FAIL, GET_CARD_SUCCESS,
    REMOVE_CARD, REMOVE_CARD_FAIL,
    SET_CARD_AS_DEFAULT
} from './payment.types';

import axios from 'axios'

export const getCards = () => {
    return (dispatch) => {
        dispatch(cards())
        return (
            axios.get(`/payment/cards`)
                .then(({ data }) => {
                    return dispatch(cardsSuccess(data))
                })
                .catch(err => dispatch(cardsFail()))
        )
    }
}

export const deleteCard = (card_id) => {
    return (dispatch) => {
        dispatch(removeCard())
        return (
            axios.delete(`/payment/cards/${card_id}`)
                .then(res => {
                    console.log(res);
                    return dispatch(getCards())
                })
                .catch(err => dispatch(removeCardFail()))
        )
    }
}

export const setCardAsDefault = (card_id) => {
    return (dispatch) => {
        dispatch(cardToDefault())
        return (
            axios.post(`/payment/cards/set-default`, { payment_card_id: card_id })
                .then(res => {
                    return dispatch(getCards())
                })
                .catch(err => console.log(err))
        )
    }
}

export const addPaymentMethods = paymentMethods => ({
    type: ADD_PAYMENT_METHOD,
    payload: paymentMethods
})

export const selectPaymentMethod = paymentMethods => ({
    type: SELECT_METHOD,
    payload: paymentMethods
})

export const setDefault = paymentMethods => ({
    type: SET_DEFAULT,
    payload: paymentMethods
})

const cards = () => {
    return {
        type: GET_CARDS
    }
}

const cardsSuccess = (data) => {
    return {
        type: GET_CARDS_SUCCESS,
        data
    }
}

const cardsFail = () => {
    return {
        type: GET_CARDS_FAIL
    }
}

const removeCard = () => {
    return {
        type: REMOVE_CARD
    }
}

const removeCardFail = () => {
    return {
        type: REMOVE_CARD_FAIL
    }
}

const cardToDefault = () => {
    return {
        type: SET_CARD_AS_DEFAULT
    }
}