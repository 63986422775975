import {
    ADD_DELIVERY_ADDRESS, ADD_DELIVERY_ADDRESS_FAIL, ADD_DELIVERY_ADDRESS_SUCCESS,
    GET_DELIVERY_ADDRESSES, GET_DELIVERY_ADDRESSES_FAIL, GET_DELIVERY_ADDRESSES_SUCCESS,
    SET_ADDRESS_DEFAULT, DELETE_ADDRESS,
    CLEAR_ERRORS,
    SET_MAP_LATLNG
} from './delivery.types';

const initialState = {
    addresses: [],
    mapLatLng: {},
    isFetching: false,
    error: false,
    errorFields: {}
}

const deliveryReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DELIVERY_ADDRESS:
            return {
                ...state,
                isFetching: true
            }
        case ADD_DELIVERY_ADDRESS_SUCCESS:
            return {
                ...state,
                isFetching: false,
                addresses: [...state.addresses, action.data],
                error: false,
                errorFields: {}
            }
        case ADD_DELIVERY_ADDRESS_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true,
                errorFields: action.error
            }
        case GET_DELIVERY_ADDRESSES:
            return {
                ...state,
                isFetching: true
            }
        case GET_DELIVERY_ADDRESSES_SUCCESS:
            return {
                ...state,
                isFetching: false,
                addresses: action.data
            }
        case GET_DELIVERY_ADDRESSES_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case SET_ADDRESS_DEFAULT:
            return {
                ...state,
            }
        case DELETE_ADDRESS:
            return {
                ...state,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                error: false,
                errorFields: {}
            }
        case SET_MAP_LATLNG:
            return {
                ...state,
                mapLatLng: action.data
            }
        default:
            return state;
    }
}

export default deliveryReducer;