import {
    API_ADD_ITEM, API_ADD_ITEM_FAIL, API_ADD_ITEM_SUCCESS,
    LOAD_CART, LOAD_CART_FAIL, LOAD_CART_SUCCESS,
    INCREASE_QUANTITY, INCREASE_QUANTITY_FAIL, INCREASE_QUANTITY_SUCCESS,
    DECREASE_QUANTITY, DECREASE_QUANTITY_FAIL, DECREASE_QUANTITY_SUCCESS,
    DELETE_ITEM, DELETE_ITEM_FAIL, DELETE_ITEM_SUCCESS
} from './cart.types';
import { addItemToCart, increaseQuantity, decreaseQuantity } from './cart.utils';

const initialState = {
    cartList: [],
    isFetching: false,
    error: false
}

const cartReducer = (state = initialState, action) => {
    switch (action.type) {
        case API_ADD_ITEM:
            return {
                ...state,
                isFetching: true
            }
        case API_ADD_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
                cartList: action.data
            }
        case API_ADD_ITEM_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case LOAD_CART:
            return {
                ...state,
                isFetching: true
            }
        case LOAD_CART_SUCCESS:
            return {
                ...state,
                isFetching: false,
                cartList: action.data
            }
        case LOAD_CART_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case INCREASE_QUANTITY:
            return {
                ...state,
                isFetching: true
            }
        case INCREASE_QUANTITY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                cartList: action.data
            }
        case INCREASE_QUANTITY_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case DECREASE_QUANTITY:
            return {
                ...state,
                isFetching: true
            }
        case DECREASE_QUANTITY_SUCCESS:
            return {
                ...state,
                isFetching: false,
                cartList: action.data
            }
        case DECREASE_QUANTITY_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        case DELETE_ITEM:
            return {
                ...state,
                isFetching: true
            }
        case DELETE_ITEM_SUCCESS:
            return {
                ...state,
                isFetching: false,
            }
        case DELETE_ITEM_FAIL:
            return {
                ...state,
                isFetching: false,
                error: true
            }
        default:
            return state;
    }
}

export default cartReducer;